import { template } from "@ember/template-compiler";
import { t } from 'ember-intl';
import type { TemplateOnlyComponent as TOC } from '@ember/component/template-only';
export interface S {
    Element: HTMLDivElement;
}
const TuitionAssistanceDashboardLpnBanner: TOC<S> = template(`
  <div class="max-w-screen-lg border border-tio-gray-200 bg-tio-gray-100 p-2 rounded-lg my-2">
    <h1 class="font-semibold text-lg">{{t "tuition_assistance.lpn.banner_header"}}</h1>
    <h3 class="text-tio-gray-800 font-medium my-2 text-sm">
      {{t "tuition_assistance.lpn.subheader"}}
    </h3>
    <section
      class="grid sm:grid-cols-1 md:grid-cols-6 lg:grid-cols-9 gap-2 text-tio-gray-800 text-sm mb-4"
    >
      <div
        class="sm:col-span-1 md:col-span-2 lg:col-span-3 border border-tio-gray-200 bg-white rounded-lg p-4 mx-2"
      >
        <h4 class="underline font-semibold mb-1 text-sm">
          {{t "tuition_assistance.lpn.uagc.default"}}
        </h4>
        <ul class="list-disc list-outside tracking-wide mx-6 text-xs">
          <li>{{t "tuition_assistance.lpn.uagc.item_1"}}</li>
          <li>{{t "tuition_assistance.lpn.uagc.item_2"}}</li>
          <li>{{t "tuition_assistance.lpn.uagc.item_3"}}</li>
        </ul>
      </div>

      <div
        class="sm:col-span-1 md:col-span-2 lg:col-span-3 border border-tio-gray-200 bg-white rounded-lg p-4 mx-2"
      >
        <h4 class="underline font-semibold mb-1 text-sm">
          {{t "tuition_assistance.lpn.devry.default"}}
        </h4>
        <ul class="list-disc list-outside tracking-wide mx-6 text-xs">
          <li>{{t "tuition_assistance.lpn.devry.item_1"}}</li>
          <li>{{t "tuition_assistance.lpn.devry.item_2"}}</li>
        </ul>
      </div>

      <div
        class="sm:col-span-1 md:col-span-2 lg:col-span-3 border border-tio-gray-200 bg-white rounded-lg p-4 mx-2"
      >
        <h4 class="underline font-semibold mb-1 text-sm">
          {{t "tuition_assistance.lpn.uopx.default"}}
        </h4>
        <ul class="list-disc list-outside tracking-wide mx-6 text-xs">
          <li>{{t "tuition_assistance.lpn.uopx.item_1"}}</li>
        </ul>
      </div>
    </section>

    <div class="my-2">
      <h2 class="mt-4 mb-2">{{t "tuition_assistance.lpn.accessing_your_discount_is_easy"}}</h2>
      <p class="text-tio-gray-800 font-medium text-xs">
        {{t "tuition_assistance.lpn.when_enrolling_for_your_next_course"}}
      </p>
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default TuitionAssistanceDashboardLpnBanner;
