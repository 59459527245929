import ApplicationAdapter from './application';
import { service } from '@ember/service';
import type TasProgramInstance from 'tio-common/models/tas-program-instance';
import type SessionContextService from '../services/session-context';

/**
 * An extended adapter for the `TasProgramInstance` model.
 *
 * @memberof adapters
 */
class TasProgramInstanceAdapter extends ApplicationAdapter {
  @service declare sessionContext: SessionContextService;
  /**
   * @param {TasProgramInstance}  tasProgramInstance - the TasProgramInstance model for which to perform the action
   * @param {string} actionEndpoint - the server endpoint to append (ex. '/request-courses-approval')
   * @return {TasProgramInstance}
   */
  async triggerActionAndReload(
    tasProgramInstance: TasProgramInstance,
    actionEndpoint: string,
    comment = ''
  ) {
    if (!tasProgramInstance || !actionEndpoint) {
      return;
    }
    const url = this.buildURL('tas-program-instance', tasProgramInstance.id) + actionEndpoint;

    const name = this.sessionContext.user?.person?.fullName || '';
    const userId = this.sessionContext.user?.id || '';
    const currentRole = this.sessionContext.currentRole;
    const actingWithRole = {
      role: currentRole?.role,
      relationshipType: currentRole?.relationshipType,
    };

    await this.ajax(url, 'PATCH', {
      data: {
        note: comment,
        by: {
          name,
          userId,
          actingWithRole,
        },
      },
    });
    return await tasProgramInstance.reload();
  }

  /**
   * @param {TasProgramInstance} tasProgramInstance - the tasProgramInstance to submit for pre-approval
   * @return {TasProgramInstance}
   */
  async requestProgramApproval(tasProgramInstance: TasProgramInstance, comment = '') {
    return this.triggerActionAndReload(tasProgramInstance, '/request-program-approval', comment);
  }

  async approveProgram(tasProgramInstance: TasProgramInstance, comment = '') {
    return this.triggerActionAndReload(tasProgramInstance, '/approve-program', comment);
  }

  async rejectProgram(tasProgramInstance: TasProgramInstance, comment = '') {
    return this.triggerActionAndReload(tasProgramInstance, '/reject-program', comment);
  }

  async requestChanges(tasProgramInstance: TasProgramInstance, comment = '') {
    return this.triggerActionAndReload(tasProgramInstance, '/decline-program', comment);
  }

  async requestProgramCompletion(tasProgramInstance: TasProgramInstance, comment = '') {
    return this.triggerActionAndReload(tasProgramInstance, '/request-completion-approval', comment);
  }

  async approveProgramCompletion(tasProgramInstance: TasProgramInstance, comment = '') {
    return this.triggerActionAndReload(tasProgramInstance, '/approve-completion', comment);
  }

  async rejectProgramCompletion(tasProgramInstance: TasProgramInstance, comment = '') {
    return this.triggerActionAndReload(tasProgramInstance, '/reject-completion', comment);
  }

  async requestProgramCompletionChanges(tasProgramInstance: TasProgramInstance, comment = '') {
    return this.triggerActionAndReload(tasProgramInstance, '/decline-completion', comment);
  }

  async requestProgramWithdrawal(tasProgramInstance: TasProgramInstance, comment = '') {
    return this.triggerActionAndReload(tasProgramInstance, '/request-withdrawal-approval', comment);
  }

  async approveProgramWithdrawal(tasProgramInstance: TasProgramInstance, comment = '') {
    return this.triggerActionAndReload(tasProgramInstance, '/approve-withdrawal', comment);
  }

  async rejectProgramWithdrawal(tasProgramInstance: TasProgramInstance, comment = '') {
    return this.triggerActionAndReload(tasProgramInstance, '/reject-withdrawal', comment);
  }

  async requestProgramWithdrawalChanges(tasProgramInstance: TasProgramInstance, comment = '') {
    return this.triggerActionAndReload(tasProgramInstance, '/decline-withdrawal', comment);
  }

  async abandonProgram(tasProgramInstance: TasProgramInstance, comment = '') {
    return this.triggerActionAndReload(tasProgramInstance, '/abandon', comment);
  }

  async fetchTasProgramLimitForInstance(tasProgramInstance: TasProgramInstance) {
    const url = `${this.buildURL('tas-program-instance', tasProgramInstance.id)}/limit`;

    return await this.ajax(url, 'GET');
  }

  async insertReviewer(
    tasProgramInstance: TasProgramInstance,
    employeeId: number,
    ordinal: number
  ) {
    if (!tasProgramInstance || !employeeId || !ordinal) {
      throw new Error('Missing required parameters');
    }

    const url = this.buildURL('tas-program-instance', tasProgramInstance.id) + '/insert-reviewer';
    await this.ajax(url, 'PATCH', {
      data: {
        'employee-id': employeeId,
        ordinal,
      },
    });

    return await tasProgramInstance.reload();
  }
}

export default TasProgramInstanceAdapter;
