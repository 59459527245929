import { template } from "@ember/template-compiler";
import { action } from '@ember/object';
import { concat } from '@ember/helper';
import { fn } from '@ember/helper';
import { gte } from 'ember-truth-helpers';
import { LinkTo } from '@ember/routing';
import { on } from '@ember/modifier';
import { service } from '@ember/service';
import { t } from 'ember-intl';
import { tracked } from '@glimmer/tracking';
import { trackedFunction } from 'reactiveweb/function';
import AppContentCard from 'tio-common/components/app/content-card';
import checkBulletListsAreSame from 'tio-employee/utils/recommendations/check-bullet-lists-are-same';
import Component from '@glimmer/component';
import ForgivenessEstimatorEstimatorSummaryDialog from 'tio-employee/components/forgiveness-estimator/estimator-summary-dialog';
import safeFormatNumber from 'tio-common/helpers/safe-format-number';
import screenIs from 'tio-common/helpers/screen-is';
import TioButton from 'tio-common/components/tio/button';
import TioDownloadButton from 'tio-common/components/tio/download-button';
import { Divider } from 'tio-ui/components/utilities';
import TioPdfPrintButton from 'tio-common/components/tio/pdf/print-button';
import type IntlService from 'ember-intl/services/intl';
import type RecommendationModel from 'tio-common/models/recommendation';
import type SessionContextService from 'tio-employee/services/session-context';
import type StoreService from 'tio-common/services/store';
import type StrategyFinderService from 'tio-common/services/strategy-finder';
export interface RecommendationsResultsResultsDetailsSignature {
    Args: {
        programType: keyof typeof PROGRAM_ATTRIBUTES;
        recommendation: RecommendationModel;
        hideHeaderSection?: boolean;
    };
}
export const PROGRAM_ATTRIBUTES = {
    pslf: {
        headerTitleKey: 'recommendations_estimator.forgiveness_summary',
        linkBackToEstimator: 'forgiveness-estimator',
        routeToProgram: 'pslf.dashboard',
        linkToNextSteps: 'forgiveness-estimator.results.next-steps'
    },
    sf: {
        headerTitleKey: 'common.strategy_finder.default',
        linkBackToEstimator: 'questionnaire',
        routeToProgram: 'repaying-student-debt.repayment-strategy-finder.dashboard',
        linkToNextSteps: 'questionnaire.results.next-steps'
    },
    idr: {
        headerTitleKey: 'recommendations_estimator.monthly_payment_and_forgiveness_results',
        linkBackToEstimator: 'forgiveness-estimator',
        routeToProgram: 'idr.dashboard',
        linkToNextSteps: 'forgiveness-estimator.results.next-steps'
    }
};
type verticalHeaderRow = {
    title: string;
    subtitle?: string;
    dataKey: string;
    formatter: string;
    footnote?: string;
    asterisk?: boolean;
};
export default class RecommendationsResultsResultsDetailsComponent extends Component<RecommendationsResultsResultsDetailsSignature> {
    @service
    intl: IntlService;
    @service
    sessionContext: SessionContextService;
    @service
    store: StoreService;
    @service
    strategyFinder: StrategyFinderService;
    @tracked
    showEstimatorSummaryDialog = false;
    @tracked
    showBestHeaderTitle = true;
    @tracked
    showCurrentHeaderTitle = true;
    get currentAttributes() {
        return PROGRAM_ATTRIBUTES[this.args.programType] || {};
    }
    get headerTitle() {
        return this.intl.t(this.currentAttributes.headerTitleKey);
    }
    get routeToProgram() {
        return `authenticated.${this.currentAttributes.routeToProgram}`;
    }
    get linkBackToEstimator() {
        return `${this.routeToProgram}.${this.currentAttributes.linkBackToEstimator}`;
    }
    get linkToNextSteps() {
        return `${this.routeToProgram}.${this.currentAttributes.linkToNextSteps}`;
    }
    get estimatedMonthlyPayment() {
        return this.args.recommendation?.estimatedMonthlyPayment;
    }
    get estimatedForgivenessAmount() {
        return this.args.recommendation?.estimatedForgivenessAmount || 0;
    }
    get estimatedForgivenessDate() {
        return this.args.recommendation?.estimatedForgivenessDate;
    }
    get currentPlanSummary() {
        return this.args.recommendation?.formattedCurrentPlanSummary;
    }
    get bestPlanSummary() {
        return this.args.recommendation?.formattedBestPlanSummary;
    }
    get estimatedMonthlySavings() {
        return this.args.recommendation?.estimatedMonthlySavings;
    }
    get verticalHeaderRows(): verticalHeaderRow[] {
        return [
            {
                title: this.intl.t('recommendations_estimator.estimated_total_to_pay'),
                dataKey: 'payoffAmount',
                formatter: 'formatMoney'
            },
            {
                title: this.intl.t('recommendations_estimator.years_left_to_repay'),
                dataKey: 'termMonths',
                formatter: 'formatTermMonths'
            },
            {
                title: this.intl.t('recommendations_estimator.monthly_payment'),
                dataKey: 'monthlyPayments',
                formatter: 'formatMoney',
                asterisk: true
            },
            {
                title: this.intl.t('recommendations_estimator.estimated_total_interest_paid'),
                dataKey: 'totalInterestPaid',
                formatter: 'formatMoney'
            },
            {
                title: this.intl.t('recommendations_estimator.estimated_amount_forgiven'),
                subtitle: this.intl.t('recommendations_estimator.estimated_amount_forgiven_includes_interest'),
                dataKey: 'remLoanBalance',
                formatter: 'formatMoney'
            }
        ];
    }
    get bestHeaderColumn() {
        return [
            this.bestPlanColumn
        ];
    }
    get currentHeaderColumn() {
        return [
            this.currentPlanColumn
        ];
    }
    get headerTranslation() {
        return this.args.programType === 'idr' ? this.intl.t('recommendations_estimator.your_income_driven_repayment_option') : this.intl.t('recommendations_estimator.top_strategy');
    }
    get workflowName() {
        return this.args.programType === 'sf' ? this.intl.t('common.strategy_finder.default') : this.intl.t('recommendations_estimator.forgiveness_estimator');
    }
    get bestPlanColumn() {
        const bestPlanHeader1 = this.args.recommendation?.bestPlanTableHeadings || [];
        if (bestPlanHeader1.length > 1) {
            this.formatHeader('best', bestPlanHeader1);
            if (!this.showBestHeaderTitle) {
                const bestPlanHeaderNoDuplicates1 = bestPlanHeader1.slice(0, 1);
                return {
                    title: this.headerTranslation,
                    headers: bestPlanHeaderNoDuplicates1
                };
            }
        }
        return {
            title: this.headerTranslation,
            headers: bestPlanHeader1
        };
    }
    get currentPlanColumn() {
        const currentPlanHeader1 = this.args.recommendation?.currentPlanTableHeadings || [];
        if (currentPlanHeader1.length > 1) {
            this.formatHeader('current', currentPlanHeader1);
            if (!this.showCurrentHeaderTitle) {
                const currentPlanHeaderNoDuplicates1 = currentPlanHeader1.slice(0, 1);
                return {
                    title: this.intl.t('recommendations_estimator.your_current_plan'),
                    headers: currentPlanHeaderNoDuplicates1
                };
            }
        }
        return {
            title: this.intl.t('recommendations_estimator.your_current_plan'),
            headers: currentPlanHeader1
        };
    }
    get tableRowData() {
        return this.verticalHeaderRows.map((row1)=>{
            // @ts-expect-error: why would the getter return null?
            const col1Value1 = this.bestPlanSummary[row1.dataKey];
            // @ts-expect-error: why would the getter return null?
            const col2Value1 = this.currentPlanSummary[row1.dataKey];
            return {
                title: row1.title,
                subtitle: row1.subtitle,
                col1Data: this.formatData(row1.formatter, col1Value1),
                col2Data: this.formatData(row1.formatter, col2Value1),
                asterisk: row1.asterisk,
                footnote: row1.footnote
            };
        });
    }
    get bestPlanSummaryCard() {
        return this.verticalHeaderRows.map((row1)=>{
            // @ts-expect-error: why would the getter return null?
            const planText1 = this.bestPlanSummary[row1.dataKey];
            return {
                title: row1.title,
                subtitle: row1.subtitle,
                footnote: row1.footnote,
                asterisk: row1.asterisk,
                planText: this.formatData(row1.formatter, planText1)
            };
        });
    }
    get currentPlanSummaryCard() {
        return this.verticalHeaderRows.map((row1)=>{
            // @ts-expect-error: why would the getter return null?
            const planText1 = this.currentPlanSummary[row1.dataKey];
            return {
                title: row1.title,
                subtitle: row1.subtitle,
                planText: this.formatData(row1.formatter, planText1)
            };
        });
    }
    personState = trackedFunction(this, async ()=>{
        const { user: user1 } = this.sessionContext;
        const person1 = await user1?.person;
        if (!person1) {
            return;
        }
        // If this has data, we've already loaded what we need into the store
        if (person1.allActiveAccountLoans.length) {
            return person1;
        }
        const personWithAccountsAndLoans1 = await this.store.findRecord('person', person1.id);
        return personWithAccountsAndLoans1;
    });
    get firstName() {
        return this.personState.value?.firstName;
    }
    get showReducedResultCard() {
        return this.args.hideHeaderSection ? false : true;
    }
    get formattedDate() {
        return this.formatDate(this.args.recommendation.createdAt);
    }
    @action
    formatData(formatFunctionName1: string, data1: unknown) {
        // @ts-expect-error: we should access this like this.
        return this[formatFunctionName1]?.(data1) || data1;
    }
    @action
    formatMoney(centsRange1 = []) {
        return this.strategyFinder.formatCentsRange(centsRange1);
    }
    @action
    formatTermMonths(monthsRange1 = []) {
        return this.strategyFinder.formatMonthsRange(monthsRange1);
    }
    @action
    formatHeader(headerType1: string, content1: object[]) {
        // @ts-expect-error: how do we unify bullet list with type returned from server?
        if (headerType1 === 'current') this.showCurrentHeaderTitle = !checkBulletListsAreSame(content1);
        // @ts-expect-error: how do we unify bullet list with type returned from server?
        if (headerType1 === 'best') this.showBestHeaderTitle = !checkBulletListsAreSame(content1);
    }
    @action
    formatDate(date1: string) {
        return this.strategyFinder.formatDateRange([
            date1
        ]);
    }
    static{
        template(`
    <h1 class="text-midnight text-sm font-semibold md:mb-6">
      {{this.headerTitle}}
    </h1>

    {{#if @recommendation.hasErrorsInCalcs}}
      <div class="m-2">
        {{t "pslf_dashboard.results.error"}}
        <LinkTo @route={{this.linkBackToEstimator}} class="tio-copy">
          {{this.workflowName}}
        </LinkTo>
        {{concat ". " (t "pslf_dashboard.results.thank_you")}}
      </div>
    {{else}}
      {{#if this.showReducedResultCard}}
        <div class="my-4">
          <p class="md:mx-20 text-sm font-semibold">
            {{this.firstName}},
            {{t "recommendations_estimator.based_on_the_info_1"}}
            <button
              class="text-ocean-600 font-semibold underline"
              type="button"
              {{on "click" (fn (mut this.showEstimatorSummaryDialog) true)}}
            >
              {{t "recommendations_estimator.information"}}
            </button>
            {{t "recommendations_estimator.based_on_the_info_2"}}
          </p>
        </div>

        <AppContentCard @title="" class="!bg-white md:max-w-fit mx-4 md:mx-20">
          <div class="grid grid-rows-2 grid-flow-col gap-2 place-items-center">
            <h3 class="font-semibold md:text-xs text-tio-gray-700 ml-2 text-center">
              {{t "recommendations_estimator.monthly_payment_card" htmlSafe=true}}
            </h3>
            <p class="font-bold text-midnight ml-2 text-lg">
              {{#if (gte this.estimatedMonthlyPayment 0)}}
                {{safeFormatNumber
                  this.estimatedMonthlyPayment
                  style="currency"
                  currency="USD"
                  maximumFractionDigits=0
                }}*
              {{/if}}
            </p>
            <h3 class="font-semibold text-tio-gray-700 ml-6 text-center md:text-left md:text-xs">
              {{t "recommendations_estimator.loan_forgiveness_card" htmlSafe=true}}
            </h3>
            <p class="font-bold text-midnight ml-6 text-lg">
              {{#if (gte this.estimatedForgivenessAmount 0)}}
                {{safeFormatNumber
                  this.estimatedForgivenessAmount
                  style="currency"
                  currency="USD"
                  maximumFractionDigits=0
                }}
              {{/if}}
            </p>
          </div>
          {{#if (gte this.estimatedMonthlySavings 0)}}
            <p class="text-tio-gray-700 ml-23 text-center md:text-xs">
              {{t
                "recommendations_estimator.savings"
                text=(safeFormatNumber
                  this.estimatedMonthlySavings
                  style="currency"
                  currency="USD"
                  maximumFractionDigits=0
                )
              }}
            </p>
          {{/if}}
        </AppContentCard>
      {{/if}}
      {{#if (screenIs "md")}}
        <div class="mx-20 my-4">
          <div class="mb-0 border border-b-0">
            <div class="flex flex-row-reverse text-sm">
              <TioPdfPrintButton
                @url={{@recommendation.downloadUrl}}
                @filename={{@recommendation.downloadFilename}}
                class="border-none"
              />
              <TioDownloadButton
                @url={{@recommendation.downloadUrl}}
                @filename={{@recommendation.downloadFilename}}
                class="border-none"
              />
            </div>
          </div>
          <table class="text-sm w-full mb-4 pslf-dashboard-results-table border table-fixed">
            <thead>
              <tr>
                <td class="border"></td>
                {{#each this.bestHeaderColumn as |column|}}
                  <th
                    scope="col"
                    class="text-left align-top even:bg-ocean-600 even:text-white border font-semibold"
                  >
                    <p class="mb-2 font-bold text-lg">{{column.title}}</p>

                    <div class="lg:m-4">
                      {{#each column.headers as |header|}}
                        {{#if this.showBestHeaderTitle}}
                          {{! @glint-expect-error: need types }}
                          <p class="mb-1">{{header.title}}</p>
                        {{/if}}
                        <ul class="list-disc list-inside mb-2">
                          {{! @glint-expect-error: need types }}
                          {{#each header.bullets as |bullet|}}
                            {{#if bullet}}
                              <li>{{bullet}}</li>
                            {{/if}}
                          {{/each}}
                        </ul>
                      {{/each}}
                    </div>
                  </th>
                {{/each}}
                {{#each this.currentHeaderColumn as |column|}}
                  <th
                    scope="col"
                    class="text-left align-top even:bg-ocean-600 even:text-white border font-semibold"
                  >
                    <p class="mb-2 font-bold text-lg">{{column.title}}</p>

                    <div class="lg:m-4">
                      {{#each column.headers as |header|}}
                        {{#if this.showCurrentHeaderTitle}}
                          {{! @glint-expect-error: need types }}
                          <p class="mb-1">{{header.title}}</p>
                        {{/if}}
                        <ul class="list-disc list-inside mb-2">
                          {{! @glint-expect-error: need types }}
                          {{#each header.bullets as |bullet|}}
                            {{#if bullet}}
                              <li>{{bullet}}</li>
                            {{/if}}
                          {{/each}}
                        </ul>
                      {{/each}}
                    </div>
                  </th>
                {{/each}}
              </tr>
            </thead>
            <tbody>
              {{#each this.tableRowData as |row|}}
                <tr>
                  <th scope="row" class="text-left align-top text-gray-700 border">
                    {{row.title}}
                    {{#if row.subtitle}}
                      <div class="text-xs font-normal pl-2">{{row.subtitle}}</div>
                    {{/if}}
                  </th>
                  <td class="text-center align-top bg-ocean-50 border">{{row.col1Data}}
                    {{#if row.asterisk}}
                      <span>*</span>
                    {{/if}}
                  </td>
                  <td class="text-center align-top border">{{row.col2Data}}</td>
                </tr>
              {{/each}}
            </tbody>
          </table>
        </div>
        {{#if this.showReducedResultCard}}
          <div class="mb-8 flex justify-center">
            <TioButton @linkTo={{this.linkToNextSteps}} class="min-w-56">
              {{t "continue"}}
            </TioButton>
          </div>
        {{/if}}
      {{else}}
        <AppContentCard
          @title={{this.bestPlanColumn.title}}
          @titleClass="uppercase text-ocean-600 text-lg font-semibold"
          class="bg-ocean-50 m-4"
        >
          <div class="ml-4 text-ocean-600 font-semibold">
            {{#each this.bestPlanColumn.headers as |header|}}
              {{! @glint-expect-error: need types }}
              <p class="mb-1">{{header.title}}</p>
              <ul class="list-disc list-inside mb-2">
                {{! @glint-expect-error: need types }}
                {{#each header.bullets as |bullet|}}
                  {{#if bullet}}
                    <li>{{bullet}}</li>
                  {{/if}}
                {{/each}}
              </ul>
            {{/each}}
          </div>
          <Divider class="my-2" />
          <div class="ml-4">
            {{#each this.bestPlanSummaryCard as |data|}}
              <div class="my-2">
                <h2 class="font-semibold text-gray-700">{{data.title}}</h2>
                {{#if data.subtitle}}
                  <p class="text-xs font-normal mx-1 text-gray-700">{{data.subtitle}}</p>
                {{/if}}
                <div class="flex">
                  <p class="ml-4">{{data.planText}}</p>
                  {{#if data.asterisk}}
                    <span>*</span>
                  {{/if}}
                </div>
                {{#if data.footnote}}
                  <p class="text-xs font-normal pl-2">{{data.footnote}}</p>
                {{/if}}
              </div>
            {{/each}}
          </div>
          <div class="flex justify-end">
            <TioPdfPrintButton
              @url={{@recommendation.downloadUrl}}
              @filename={{@recommendation.downloadFilename}}
              class="border-none bg-transparent"
            />
            <TioDownloadButton
              @url={{@recommendation.downloadUrl}}
              @filename={{@recommendation.downloadFilename}}
              class="border-none bg-transparent"
            />
          </div>
        </AppContentCard>
        <AppContentCard
          @title={{this.currentPlanColumn.title}}
          @titleClass="uppercase text-ocean-600 text-lg font-semibold"
          class="m-4"
        >
          <div class="ml-4 text-ocean-600 font-semibold">
            {{#each this.currentPlanColumn.headers as |header|}}
              {{! @glint-expect-error: need types }}
              <p class="mb-1">{{header.title}}</p>
              <ul class="list-disc list-inside mb-2">
                {{! @glint-expect-error: need types }}
                {{#each header.bullets as |bullet|}}
                  {{#if bullet}}
                    <li>{{bullet}}</li>
                  {{/if}}
                {{/each}}
              </ul>
            {{/each}}
          </div>
          <Divider class="my-2" />
          <div class="ml-4">
            {{#each this.currentPlanSummaryCard as |data|}}
              <div class="my-2">
                <h2 class="font-semibold text-gray-700">{{data.title}}</h2>
                {{#if data.subtitle}}
                  <p class="text-xs font-normal mx-1 text-gray-700">{{data.subtitle}}</p>
                {{/if}}
                <p class="ml-4">{{data.planText}}</p>
              </div>
            {{/each}}
          </div>
        </AppContentCard>
        {{#if this.showReducedResultCard}}
          <div class="flex justify-center">
            <TioButton @linkTo={{this.linkToNextSteps}} class="min-w-56">
              {{t "continue"}}
            </TioButton>
          </div>
        {{/if}}
      {{/if}}
    {{/if}}

    {{#if this.showEstimatorSummaryDialog}}
      <ForgivenessEstimatorEstimatorSummaryDialog
        @programType={{@programType}}
        {{! @glint-expect-error: need to get definitive answer on mut }}
        @didCancel={{fn (mut this.showEstimatorSummaryDialog) false}}
        @recommendation={{@recommendation}}
      />
    {{/if}}
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
