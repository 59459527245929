import { service } from '@ember/service';
import Route from '@ember/routing/route';
import type StoreService from 'tio-common/services/store';

export default class ResetPasswordRoute extends Route {
  @service declare store: StoreService;

  queryParams = {
    token: { refreshModel: true, replace: true },
    'ember-reset-password': { refreshModel: false },
  };

  async model() {
    const query = location.search;
    const params = new URLSearchParams(query);
    const token = params.get('token');
    const emberResetPassword = params.get('ember-reset-password');

    if (!emberResetPassword) return;

    const validationRes = await this.store.adapterFor('login').validateResetPasswordToken(token);

    if (!token || !validationRes?.success) {
      return {
        error: 'Invalid token',
      };
    }
  }
}
