import { service } from '@ember/service';
import Route from '@ember/routing/route';
import type StoreService from 'tio-common/services/store';
import type { TasAppHistory, TasAppWithHistory } from 'tio-common/types/tuition-assistance';

const fixtureData: TasAppHistory[] = [
  {
    details: 'Repayment of $30.00',
    date: '2020-09-20',
    icon: 'UserIcon',
    iconBackground: 'bg-gray-400',
  },
  {
    details: 'Repayment request of $60.00 from Mariah Carey',
    date: '2020-09-22',
    icon: 'HandThumbUpIcon',
    iconBackground: 'bg-blue-500',
  },
  {
    approverName: 'Janis Ian - Approver',
    approverComment: 'Called client, they reassured me the invoice would be paid by the 25th.',
    details: 'Payment request of $199.50 made to Bank Account',
    date: '2020-09-28',
    icon: 'CheckIcon',
    iconBackground: 'bg-green-500',
  },
  {
    details: 'Application approved by Chappell Roan', // Corrected line
    date: '2020-09-30',
    icon: 'Document',
    iconBackground: 'bg-blue-500',
  },
  {
    details: 'Application Submitted for approval by Troye Sivan',
    date: '2020-10-04',
    icon: 'CurrencyDollar',
    iconBackground: 'bg-green-500',
  },
  {
    details: 'Not much to see here',
    date: '2024-10-16',
  },
  {
    details: 'Not much to see there either',
    date: '2024-10-16',
  },
];

export default class AuthenticatedTasApplicationsHistoryRoute extends Route {
  @service declare store: StoreService;

  includes = [
    'tas-courses.tas-assets',
    'tas-program-instance.tas-program-template',
    'tas-program-instance.employee',
    'tas-program-instance.employee.company.comments',
    'tas-program-instance.employee.person',
    'tas-program-instance.employee.tas-participant',
    'comments.person',
    'tas-assets',
    'tas-program-instance.tas-assets',
    'tas-assistances',
    'tas-program-limit',
  ];

  async model({ tas_application_id }: { tas_application_id: string }): Promise<TasAppWithHistory> {
    const application = await this.store.findRecord('tas-application', tas_application_id);
    application.history = fixtureData;
    return application;
  }
}
