import { template } from "@ember/template-compiler";
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { on } from '@ember/modifier';
import { t } from 'ember-intl';
import { Input } from 'tio-ui/components/forms';
import Component from '@glimmer/component';
import RouteTemplate from 'ember-route-template';
import AppPage from 'tio-ui/components/app-page';
import InlineEdit from 'tio-ui/components/inline-edit';
import AddEducationDrawer from 'tio-employee/components/tas/add-education-drawer';
import DrawerTarget from 'tio-ui/components/drawer-target';
import AddScholarshipsDrawer from 'tio-employee/components/tas/add-scholarships-drawer';
import Plus from 'ember-static-heroicons/components/solid-24/plus';
import CloudArrowUp from 'ember-static-heroicons/components/outline-24/cloud-arrow-up';
import FileDropzone from 'ember-file-upload/components/file-dropzone';
import fileQueue from 'ember-file-upload/helpers/file-queue';
import TasPageHeader from 'tio-employee/components/tas/page-header';
import TasCardGeneric from 'tio-ui/components/card-generic';
import type { TOC } from '@ember/component/template-only';
import type ConditionModel from 'tio-common/models/condition';
import type TasApplicationModel from 'tio-common/models/tas-application';
import type TasCourseModel from 'tio-common/models/tas-course';
import type TASProgramInstanceModel from 'tio-common/models/tas-program-instance';
import { all, dropTask } from 'ember-concurrency';
import type SessionContextService from 'tio-employee/services/session-context';
import { service } from '@ember/service';
import AcceptConditions from 'tio-employee/components/tuition-assistance/forms/accept-conditions';
import type StoreService from 'tio-common/services/store';
const Header: TOC<{
    title: string;
    required?: boolean;
    subtitle: string;
}> = template(`
  <div class="my-4">
    <h1 class="text-grey-600 text-2xl">{{@title}}</h1>
    <p>
      {{#if @required}}
        <span class="text-error-400 text-xl">*</span>
      {{/if}}
      {{@subtitle}}</p>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
const Header2: TOC<{
    title?: string;
    subtitle?: string;
    required?: boolean;
    subHeader?: string;
}> = template(`
    <div>
      <h1 class="text-grey-600 text-2xl">{{@title}}
        {{#if @required}}
          <span class="text-error-400 text-2xl">*</span>
        {{/if}}
        {{#if @subHeader}}
          <span class="text-grey-400 text-lg">{{@subHeader}}</span>
        {{/if}}
      </h1>
      <p>{{@subtitle}}</p>
    </div>
  `, {
    eval () {
        return eval(arguments[0]);
    }
});
export interface TypeTwoNewSignature {
    Args: {
        model: {
            application: TasApplicationModel;
            instance: TASProgramInstanceModel;
            courses: TasCourseModel[];
        };
    };
}
let AuthenticatedTasApplicationsTypeTwoNew = class AuthenticatedTasApplicationsTypeTwoNew extends Component<TypeTwoNewSignature> {
    @tracked
    applicationName = '';
    @tracked
    isEducationOpen = false;
    @tracked
    isSchorlarshipOpen = false;
    @tracked
    acceptedConditions: ConditionModel[] = [];
    @service
    sessionContext: SessionContextService;
    @service
    store: StoreService;
    get conditionsForSubmit() {
        return this.args.model.instance.tasProgramTemplate.typeTwoApplicationConditions || [];
    }
    get hasAgreedAllConditions() {
        if (!this.conditionsForSubmit.length) {
            return true;
        }
        return this.conditionsForSubmit.length === this.acceptedConditions.length;
    }
    saveAgreementsForConditions = dropTask(async ()=>{
        const user1 = this.sessionContext.user;
        const agreements1 = this.acceptedConditions.map((condition1)=>{
            return this.store.createRecord('agreement', {
                user: user1,
                condition: condition1
            });
        });
        const promises1 = agreements1.map((agreement1)=>{
            return agreement1.save();
        });
        const saved1 = await all(promises1);
        return saved1;
    });
    @action
    didUpdateAcceptedConditions(conditions1: ConditionModel[] = []) {
        this.acceptedConditions = conditions1;
    }
    @action
    saveStartDate() {
        console.log('Save Start Date');
    }
    @action
    updateAppName(event1: Event) {
        const target1 = event1.target as HTMLInputElement;
        this.applicationName = target1.value;
    }
    @action
    toggleEducationDrawer() {
        this.isEducationOpen = !this.isEducationOpen;
    }
    @action
    toggleScholarshipDrawer() {
        this.isSchorlarshipOpen = !this.isSchorlarshipOpen;
    }
    @action
    onSave() {
        console.log('Save');
    }
    @action
    onFinishLater() {
        console.log('Finish Later');
    }
    @action
    onTrash() {
        console.log('Trash');
    }
    inputClasses = {
        base: 'w-full mb-6'
    };
    static{
        template(`
    <div class="p-4 w-full">
      {{#let (fileQueue name="photos") as |queue|}}
        <FileDropzone @queue={{queue}} as |dropzone|>
          {{#if dropzone.active}}
            <div class="fixed inset-0 bg-gray-900 bg-opacity-50 flex items-center justify-center">
              <div class="flex items-center p-4 rounded-lg border border-black">
                <CloudArrowUp class="mr-4 h-8 w-8 text-black" />
                <p>{{t "application.type_two.new.drop_files"}}</p>
              </div>
            </div>
          {{/if}}
          <AppPage>
            <:title>
              <div class="mr-6">
                <TasPageHeader>
                  <:title as |title|>
                    <title.BackLink @label="Back" @route="authenticated.tas.dashboard">
                      {{t "sidebar.tuition_assistance"}}
                    </title.BackLink>
                  </:title>
                  <:actions as |actions|>
                    <actions.Button {{on "click" this.onSave}}>{{t "save"}}</actions.Button>
                    <actions.Button {{on "click" this.onFinishLater}}>
                      {{t "application.type_two.new.finish_later"}}
                    </actions.Button>
                    <actions.IconButton {{on "click" this.onTrash}} @type="trash" />
                  </:actions>
                </TasPageHeader>
              </div>
            </:title>
            <:content>
              <DrawerTarget />
              <div class="flex">
                <div class="flex flex-col w-full md:w-2/3">
                  <Header @title="Application" @required={{true}} @subtitle="Required Items" />
                  <div class="flex items-center w-full py-4 border-b border-gray-200">
                    <div class="w-1/2">
                      <h2 class="text-grey-600 font-semibold">{{t
                          "application.type_two.new.start_date"
                        }}</h2>
                    </div>
                    <div class="w-1/2">
                      <InlineEdit
                        @value="10/31/2024"
                        @onSave={{this.saveStartDate}}
                        @inputType="date"
                      />
                    </div>
                  </div>
                  <div class="flex items-center w-full py-4 border-b border-gray-200">
                    <div class="w-1/2">
                      <h2 class="text-grey-600 font-semibold">{{t "application.type_two.new.name"}}
                        <span class="text-error-400 text-xl">*</span>
                      </h2>
                    </div>
                    <div class="w-1/2">
                      <label for="application-name" class="sr-only">{{t
                          "application.type_two.new.name"
                        }}</label>
                      <Input
                        size="lg"
                        id="application-name"
                        @value={{this.applicationName}}
                        class="!bg-transparent"
                        {{on "input" this.updateAppName}}
                      />
                    </div>
                  </div>
                  <div class="mt-20">
                    <Header2
                      @title="Add Education"
                      @subtitle="Add Education for reimbursement"
                      @required={{true}}
                    />
                  </div>
                  <div class="flex items-center w-full py-4 border-b border-gray-200">
                    <button
                      type="button"
                      class="flex py-3 px-6 me-2 mb-2 font-medium text-lg text-ocean-600 focus:outline-none bg-white rounded-lg border border-ocean-600 hover:bg-ocean-50 hover:text-ocean-700"
                      {{on "click" this.toggleEducationDrawer}}
                    >
                      <Plus class="text-ocean-600 h-6 w-6 mr-2" />
                      {{t "application.type_two.new.education"}}
                    </button>
                  </div>
                  <div class="mt-20">
                    <Header2
                      @title="Add Scholarships"
                      @subHeader="(Optional)"
                      @subtitle="Please enter any scholarships you have received during this Education."
                    />
                  </div>
                  <div class="flex items-center w-full py-4 border-b border-gray-200">
                    <button
                      type="button"
                      class="flex py-3 px-6 me-2 mb-2 font-medium text-lg text-ocean-600 focus:outline-none bg-white rounded-lg border border-ocean-600 hover:bg-ocean-50 hover:text-ocean-700"
                      {{on "click" this.toggleScholarshipDrawer}}
                    >
                      <Plus class="text-ocean-600 h-6 w-6 mr-2" />
                      {{t "application.type_two.new.scholarships"}}
                    </button>
                  </div>
                  <div class="mt-20">
                    <Header2
                      @title="Attachments"
                      @required={{true}}
                      @subtitle="Upload documents as required by your program administrator."
                    />
                  </div>
                  <div class="flex items-center w-full py-4">

                    <div class="flex items-center justify-center w-full">
                      <label
                        for="dropzone-file"
                        class="flex flex-col items-center justify-center w-full h-64 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-gray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600"
                      >
                        {{#if queue.files.length}}
                          {{t "file_upload.uploading"}}
                          {{queue.files.length}}
                          {{t "file_upload.files"}}
                          ({{queue.progress}}%)
                        {{else}}
                          <div class="flex flex-col items-center justify-center pt-5 pb-6">
                            <svg
                              class="w-8 h-8 mb-4 text-gray-500 dark:text-gray-400"
                              aria-hidden="true"
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 20 16"
                            >
                              <path
                                stroke="currentColor"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="2"
                                d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"
                              />
                            </svg>
                            <p class="mb-2 text-sm text-gray-500 dark:text-gray-400"><span
                                class="font-semibold"
                              >{{t "file_upload.select_a_file"}}</span>
                              {{t "file_upload.or_drag_and_drop"}}</p>
                            {{! <p class="text-xs text-gray-500 dark:text-gray-400">PDF, DOC, PNG, JPG
                              (Max file size 5MB)</p> }}
                          </div>
                        {{/if}}
                        <input id="dropzone-file" type="file" {{queue.selectFile}} class="hidden" />
                      </label>
                    </div>

                  </div>
                  <AddEducationDrawer
                    @isOpen={{this.isEducationOpen}}
                    @onClose={{this.toggleEducationDrawer}}
                  />
                  <AddScholarshipsDrawer
                    @isOpen={{this.isSchorlarshipOpen}}
                    @onClose={{this.toggleScholarshipDrawer}}
                  />
                </div>
                <div class="hidden md:flex flex-col w-1/3">
                  <div class="flex flex-col items-center mx-12 mt-4">
                    <TasCardGeneric>
                      <:header>
                        <h2>{{t "benefits_summary.title"}}</h2>
                      </:header>
                      <:body>
                        <div class="flex flex-col items-center">
                          <div class="flex justify-between w-full py-4">
                            <div class="flex">
                              <h2 class="text-grey-600">
                                {{t "benefits_summary.assistance_limit"}}
                              </h2>
                            </div>
                            <div class="flex text-violet-500 font-semibold">
                              {{! template-lint-disable no-bare-strings }}
                              <p>\$1750.00</p>
                            </div>
                          </div>
                        </div>
                        <div class="flex flex-col items-center">
                          <div class="flex justify-between w-full py-4">
                            <div class="flex">
                              <h2 class="text-grey-600">
                                {{t "benefits_summary.requested_amount"}}
                              </h2>
                            </div>
                            <div class="flex">
                              {{! template-lint-disable no-bare-strings }}
                              <p>-\$0.00</p>
                            </div>
                          </div>
                        </div>
                        <div class="flex flex-col items-center border-b border-gray-200">
                          <div class="flex justify-between w-full py-4">
                            <div class="flex">
                              <h2 class="text-grey-600">
                                {{t "benefits_summary.scholarships"}}
                              </h2>
                            </div>
                            <div class="flex">
                              {{! template-lint-disable no-bare-strings }}
                              <p>+\$0.00</p>
                            </div>
                          </div>
                        </div>
                        <div class="flex flex-col items-center">
                          <div class="flex justify-between w-full py-4">
                            <div class="flex">
                              <h2 class="text-grey-600">
                                {{t "benefits_summary.total_request"}}
                              </h2>
                            </div>
                            <div class="flex">
                              {{! template-lint-disable no-bare-strings }}
                              <p>+\$0.00</p>
                            </div>
                          </div>
                        </div>
                        {{#if this.conditionsForSubmit.length}}
                          <AcceptConditions
                            @conditions={{this.conditionsForSubmit}}
                            @onChange={{this.didUpdateAcceptedConditions}}
                          />
                        {{/if}}
                        <div class="py-4">
                          <button
                            type="button"
                            class="flex justify-center w-full py-3 px-6 me-2 mb-2 font-medium text-lg text-white focus:outline-none bg-gray-300 rounded-lg hover:bg-ocean-600 hover:text-white"
                            {{on "click" this.onSave}}
                          >
                            {{t "benefits_summary.submit_application"}}
                          </button>
                          <p class="text-sm font-light text-center">
                            {{t "benefits_summary.helper_text"}}
                          </p>
                        </div>
                      </:body>
                    </TasCardGeneric>
                  </div>
                </div>
              </div>
            </:content>
          </AppPage>
        </FileDropzone>
      {{/let}}
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
};
export default RouteTemplate(AuthenticatedTasApplicationsTypeTwoNew);
