//import { concat } from '@ember/helper';
import { template } from "@ember/template-compiler";
import { action, get } from '@ember/object';
import { currentEmployerApproval } from 'tio-common/validation-schema/forms/pslf-approver';
import { dropTask } from 'ember-concurrency';
import { fn } from '@ember/helper';
import { not } from 'ember-truth-helpers';
import { on } from '@ember/modifier';
import { service } from '@ember/service';
import { t } from 'ember-intl';
import { tracked } from '@glimmer/tracking';
import AppPage from 'tio-employee/components/app/page';
import AppPageContent from 'tio-employee/components/app/page/content';
import Component from '@glimmer/component';
import FormCheckbox from '@frontile/forms-legacy/components/form-checkbox';
import FormInput from '@frontile/forms-legacy/components/form-input';
import FormRadioGroup from '@frontile/forms-legacy/components/form-radio-group';
import MaterialIcon from 'tio-common/components/material-icon';
import RouteTemplate from 'ember-route-template';
import TioButton from 'tio-common/components/tio/button';
import { Divider } from 'tio-ui/components/utilities';
import tippy from 'ember-tippy/modifiers/tippy';
import type PslfFormModel from 'tio-common/models/pslf-form';
import type RouterService from '@ember/routing/router-service';
import type Store from '@ember-data/store';
export interface AdminPslfFormReviewSignature {
    Args: {
        model: PslfFormModel;
    };
    Element: HTMLElement;
}
let AdminPslfFormReviewRoute = class AdminPslfFormReviewRoute extends Component<AdminPslfFormReviewSignature> {
    @service
    store: typeof Store;
    @tracked
    employmentStartDate: string = '';
    @tracked
    employmentEndDate: string = '';
    @tracked
    currentEmployee: boolean = true;
    @tracked
    weeklyHours: number = 0;
    @tracked
    employmentStatus: string = '';
    @tracked
    employerInfoCorrect: boolean = true;
    @tracked
    loading: boolean = false;
    @service
    router: RouterService;
    get formEmployeeName() {
        return this.args.model.borrowerFields['borrower-name'];
    }
    get employerFields() {
        return this.args.model.employerFields;
    }
    get formModel() {
        return {
            employmentStartDate: this.employmentStartDate,
            employmentEndDate: this.employmentEndDate,
            weeklyHours: this.weeklyHours,
            currentEmployee: this.currentEmployee,
            employmentStatus: this.employmentStatus,
            employerInfoCorrect: this.employerInfoCorrect
        };
    }
    get formValidationErrors() {
        const schema1 = currentEmployerApproval;
        try {
            schema1?.validateSync(this.formModel, {
                abortEarly: false
            });
        } catch (e1) {
            return e1.errors || [];
        }
        return [];
    }
    @action
    blurWeeklyHoursInput() {
        let element1 = document.getElementById('weekly-hours');
        if (element1) {
            element1.blur();
        }
    }
    @action
    async submit() {
        this.submitTask.perform();
    }
    submitTask = dropTask(async ()=>{
        if (this.formValidationErrors.length) {
            console.log('validation errors', this.formValidationErrors);
            this.loading = false;
            return;
        }
        this.loading = true;
        const outgoingForm1 = this.args.model;
        outgoingForm1.employerFields = {
            ...this.args.model.employerFields,
            'employment-start-date': this.employmentStartDate,
            'employment-end-date': this.employmentEndDate,
            'employment-continues': this.currentEmployee,
            'employment-hours': this.weeklyHours.toString(),
            'employment-status': this.employmentStatus
        };
        this.router.transitionTo('authenticated.admin.pslf.form.sign', outgoingForm1.id, {
            queryParams: {
                signerType: 'employer'
            }
        });
        this.loading = false;
        return;
    });
    static{
        template(`
    <AppPage @title={{t "pslf.employer_workflow.partner_approver_page_header"}}>
      <AppPageContent>
        <form ...attributes {{on "wheel" this.blurWeeklyHoursInput}}>
          <h2 class="font-semibold text-xl mt-4 mb-">{{this.formEmployeeName}}</h2>
          {{! <p class="text-lg font-semibold">SSN: ###-##-6666</p> }}
          {{! EMPLOYER INFO TO VERIFY  }}
          <p class="mt-8 mb-4 text-lg font-semibold">{{t
              "pslf.employer_workflow.review_employer_info"
            }}</p>
          <div class="flex flex-row">
            <p class="font-semibold mr-2">{{t "name"}}:</p>
            <p>{{get this.employerFields "employer-name"}}</p>
          </div>
          <div class="flex flex-row">
            <p class="font-semibold mr-2">{{t "pslf.employer_workflow.fein_header"}}:</p>
            <p>{{get this.employerFields "employer-fein"}}</p>
          </div>
          <div class="flex flex-row">
            <p class="font-semibold mr-2">{{t "accounts.address"}}:</p>
            <p>{{@model.fullEmployerAddress}}</p>
          </div>
          {{#if (get this.employerFields "employer-website")}}
            <div class="flex flex-row">
              <p class="font-semibold mr-2">{{t "pslf.workflow.employer_website"}}:</p>
              <p>{{get this.employerFields "employer-website"}}</p>
            </div>
          {{/if}}
          <FormRadioGroup
            data-legacy-input
            @value={{this.employerInfoCorrect}}
            @onChange={{fn (mut this.employerInfoCorrect) (not this.employerInfoCorrect)}}
            @containerClass="py-4"
            as |Radio|
          >
            {{! @glint-expect-error: children of form groups }}
            <Radio @value={{true}} @label={{t "common.yes"}} />
            {{! @glint-expect-error: children of form groups }}
            <Radio @value={{false}} @label={{t "common.no"}} />
          </FormRadioGroup>

          {{#if this.employerInfoCorrect}}
            <Divider class="my-9" />
            <p class="text-lg font-semibold">
              {{t "common.pslf.employment_dates"}}</p>
            <FormCheckbox
              data-legacy-input
              @label="Still Employed"
              @checked={{this.currentEmployee}}
              @onChange={{fn (mut this.currentEmployee) (not this.currentEmployee)}}
              @containerClass="py-4"
            />

            <FormInput
              data-legacy-input
              type="date"
              @label="Start Date"
              @value={{this.employmentStartDate}}
              @onInput={{fn (mut this.employmentStartDate)}}
              @containerClass="py-4"
            />
            {{#unless this.currentEmployee}}
              <FormInput
                data-legacy-input
                type="date"
                @label="End Date"
                @value={{this.employmentEndDate}}
                @onInput={{fn (mut this.employmentEndDate)}}
              />
            {{/unless}}

            <p class="text-lg font-semibold pt-4 pb-2">
              {{t "pslf.employer_workflow.average_hours_per_week"}}
            </p>
            <p>{{t "pslf.employer_workflow.hours_sub" htmlSafe=true}}</p>
            <FormInput
              data-legacy-input
              @hint={{t "pslf.employer_workflow.average_hours_per_week"}}
              @type="number"
              {{! @glint-expect-error: wants a string}}
              @value={{this.weeklyHours}}
              {{! @glint-expect-error: wants a string}}
              @onInput={{fn (mut this.weeklyHours)}}
              @containerClass="py-4"
              id="weekly-hours"
            />
            <div class="flex flex-row items-center">
              <p class="text-lg font-semibold">{{t
                  "pslf.employer_workflow.employment_status_current"
                  name=this.formEmployeeName
                }}</p>
              <MaterialIcon
                @icon="info"
                class="mx-2 text-lg leading-5 align-bottom cursor-default text-ocean-600"
                {{tippy (t "pslf.employer_workflow.full_time_means")}}
              />
            </div>
            <FormRadioGroup
              data-legacy-input
              @value={{this.employmentStatus}}
              {{! @glint-expect-error: onChange wants a second param of the event as well as the value }}
              @onChange={{fn (mut this.employmentStatus)}}
              @containerClass="py-4"
              as |Radio|
            >{{! @glint-expect-error: children of form groups }}
              <Radio @value="fullTime" @label={{t "pslf.workflow.full_time"}} />
              {{! @glint-expect-error: children of form groups }}
              <Radio @value="partTime" @label={{t "pslf.workflow.part_time"}} />
            </FormRadioGroup>

            {{!-- <p class="text-midnight text-xl uppercase font-bold">{{t
                "pslf.employer_workflow.verify_employer_info"
              }}</p> --}}
            <Divider class="my-2" />

            <TioButton
              class="w-52 mx-auto visited:text-white my-4"
              @onClick={{this.submit}}
              @loading={{this.loading}}
              @disabled={{not this.employerInfoCorrect}}
            >{{t "common.submit"}}</TioButton>
          {{else}}
            <div class="p-4 my-4 rounded border bg-gray-200 border-gray-200 w-1/2 flex flex-row">
              <MaterialIcon @icon="info" class="m-3" />
              <p>{{t "pslf.employer_workflow.if_incorrect" email="support@tuition.io"}}</p>
            </div>
          {{/if}}
        </form>
      </AppPageContent>
    </AppPage>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
};
export default RouteTemplate(AdminPslfFormReviewRoute);
