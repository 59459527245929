import { template } from "@ember/template-compiler";
import Component from '@glimmer/component';
import RouteTemplate from 'ember-route-template';
import { t } from 'ember-intl';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import type RouterService from '@ember/routing/router-service';
import { service } from '@ember/service';
import { fn } from '@ember/helper';
import { on } from '@ember/modifier';
import pageTitle from 'ember-page-title/helpers/page-title';
import type StoreService from 'tio-common/services/store';
import type PaymentModel from 'tio-common/models/tas-payment';
import type CompanyModel from 'tio-common/models/company';
import MaterialIcon from 'tio-common/components/material-icon';
import TioPageBreadcrumbs from 'tio-common/components/tio/page/breadcrumbs';
import SelectGeneric from 'tio-common/components/select/generic';
import Paginator from 'tio-common/components/tio/paginator';
import TioButton from 'tio-common/components/tio/button';
import safeFormatDate from 'tio-common/helpers/safe-format-date';
import downloadFile from 'tio-common/utils/download-file';
import { Modal, Footer } from 'tio-ui/components/modal';
const PAYMENT_STATES = [
    'PROCESSING',
    'CREATED',
    'ABANDONED',
    'CANCELED',
    'PAID',
    'EMPTY',
    'ERRORED'
];
interface AdminTuitionAssistancePaymentsSignature {
    Args: {
        model: {
            tasPayments: PaymentModel[];
            total: number;
        };
        controller: {
            state: string;
            page: number;
        };
    };
}
let AdminTuitionAssistancePayments = class AdminTuitionAssistancePayments extends Component<AdminTuitionAssistancePaymentsSignature> {
    @service
    router: RouterService;
    @service
    store: StoreService;
    @tracked
    state?: string;
    @tracked
    selectedPayment?: PaymentModel;
    @tracked
    selectedCompany?: CompanyModel;
    @tracked
    createModalOpen: boolean = false;
    @tracked
    settleModalOpen: boolean = false;
    @tracked
    cancelModalOpen: boolean = false;
    @tracked
    isSettlingPayment: boolean = false;
    @tracked
    isCancelingPayment: boolean = false;
    get settleablePayments() {
        const { tasPayments: tasPayments1 } = this.args.model;
        return tasPayments1.filter((payment1)=>payment1.state === 'CREATED');
    }
    constructor(owner1: unknown, args1: AdminTuitionAssistancePaymentsSignature['Args']){
        super(owner1, args1);
        this.state = args1.controller.state;
    }
    @action
    setState(state1: string) {
        // needs to explicitly be undefined instead of null, otherwise the router will
        // include a blank state query param
        const newState1 = state1 ? state1 : undefined;
        this.state = newState1;
        this.router.transitionTo({
            queryParams: {
                state: newState1
            }
        });
    }
    @action
    setSelectedPayment(payment1: PaymentModel) {
        this.selectedPayment = payment1;
    }
    @action
    toggleSettleModal() {
        this.settleModalOpen = !this.settleModalOpen;
    }
    @action
    closeSettleModal() {
        delete this.selectedPayment;
        this.toggleSettleModal();
    }
    @action
    async settlePayment() {
        this.isSettlingPayment = true;
        const paymentId1 = this.selectedPayment?.id;
        if (!paymentId1) return;
        await this.store.adapterFor('tas-payment').settlePayment({
            paymentId: paymentId1
        });
        this.isSettlingPayment = false;
        this.closeSettleModal();
        this.router.refresh(this.router.currentRoute?.name);
    }
    @action
    toggleCancelModal() {
        this.cancelModalOpen = !this.cancelModalOpen;
    }
    @action
    closeCancelModal() {
        delete this.selectedPayment;
        this.toggleCancelModal();
    }
    @action
    async cancelPayment() {
        const paymentId1 = this.selectedPayment?.id;
        if (!paymentId1) return;
        this.isCancelingPayment = true;
        await this.store.adapterFor('tas-payment').cancelPayment({
            paymentId: paymentId1
        });
        this.isCancelingPayment = false;
        this.closeCancelModal();
        this.router.refresh(this.router.currentRoute?.name);
    }
    @action
    async downloadPaymentFiles(paymentId1: string) {
        if (!paymentId1) return;
        const adapter1 = this.store.adapterFor('tas-payment');
        // wrong usage of adapter!
        const headers1 = adapter1.headers;
        // wrong usage of adapter!
        const url1 = [
            adapter1.buildURL('tas-payments'),
            paymentId1,
            'download_files'
        ].join('/');
        const response1 = await fetch(url1, {
            headers: headers1
        });
        if (response1.ok) {
            downloadFile(await response1.blob(), 'tas_payment_files.zip', 'application/x-zip');
        }
    }
    static{
        template(`
    {{pageTitle (t "common.tuition_assistance.payments.default")}}
    <div class="mx-6 py-5 pr-4">
      <h1 class="text-midnight text-lg font-semibold mb-1">
        {{t "common.tuition_assistance.payments.default"}}
      </h1>
      <TioPageBreadcrumbs as |b|>
        <b.crumb
          @route="authenticated.admin.tuition-assistance"
          @label={{t "tuition_assistance.default"}}
        />
        <b.crumb
          @route="authenticated.admin.tuition-assistance.payments"
          @label={{t "tuition_assistance.payments"}}
        />
      </TioPageBreadcrumbs>
      <div class="mb-4">
        <SelectGeneric
          @label="Filter by status"
          @options={{PAYMENT_STATES}}
          @selected={{this.state}}
          @searchEnabled={{true}}
          @onChange={{this.setState}}
        >
          <:option as |option|>
            <span class="text-sm">
              {{option}}
            </span>
          </:option>
        </SelectGeneric>
      </div>
      <div class="mb-4">
        <TioButton @onClick={{this.toggleSettleModal}} class="md:w-52 !inline-block">
          {{t "tuition_assistance.admin.payments.settle"}}
        </TioButton>
        <TioButton @onClick={{this.toggleCancelModal}} class="md:w-52 !inline-block">
          {{t "tuition_assistance.admin.payments.cancel"}}
        </TioButton>
      </div>
      <div class="grid grid-rows-1 grid-cols-7 font-semibold text-xs border-b">
        <div class="px-2 pb-1 pt-2">
          {{t "common.tuition_assistance.payments.attribute_label.id"}}
        </div>
        <div class="px-2 pb-1 pt-2">
          {{t "common.tuition_assistance.payments.attribute_label.payment_date"}}
        </div>
        <div class="px-2 pb-1 pt-2">
          {{t "common.tuition_assistance.payments.attribute_label.status"}}
        </div>
        <div class="px-2 pb-1 pt-2">
          {{t "common.tuition_assistance.payments.attribute_label.type"}}
        </div>
        <div class="px-2 pb-1 pt-2">
          {{t "common.tuition_assistance.payments.attribute_label.created_by"}}
        </div>
        <div class="px-2 pb-1 pt-2">
          {{t "common.tuition_assistance.payments.attribute_label.download"}}
        </div>
      </div>
      {{#each @model.tasPayments as |payment|}}
        <div class="hover:bg-tio-gray-100 text-sm">
          <div class="grid grid-rows-1 grid-cols-7 border-b border-l border-r h-9">
            <div class="px-2 py-1 flex payments-center">
              {{payment.id}}
            </div>
            <div class="px-2 py-1 flex payments-center">
              {{#if payment.paymentDate}}
                {{safeFormatDate payment.paymentDate month="long" day="2-digit" year="numeric"}}
              {{/if}}
            </div>
            <div class="px-2 py-1 flex payments-center">
              {{payment.state}}
            </div>
            <div class="px-2 py-1 flex payments-center">
              {{payment.paymentType}}
            </div>
            <div class="px-2 py-1 flex payments-center">
              {{payment.createdBy}}
            </div>
            <div class="px-2 py-1 flex payments-center">
              <button type="button" {{on "click" (fn this.downloadPaymentFiles payment.id)}}>
                <MaterialIcon @icon="download" class="mx-2 text-ocean-600" />
              </button>
            </div>
          </div>
        </div>
      {{/each}}
      <Paginator @totalItems={{@model.tasPayments.length}} @summary={{true}} />
    </div>
    <Modal
      @isOpen={{this.settleModalOpen}}
      @onClose={{this.closeSettleModal}}
      class="min-h-64"
      @backdrop="faded"
      as |m|
    >
      <m.Header>{{t "tuition_assistance.admin.payments.settle"}}</m.Header>
      <m.Body>
        <SelectGeneric
          @label="Payment Runs"
          @options={{this.settleablePayments}}
          @selected={{this.selectedPayment}}
          @onChange={{this.setSelectedPayment}}
          @searchEnabled={{true}}
          @renderInPlace={{false}}
        >
          <:option as |option|>
            {{safeFormatDate option.paymentDate}}
            -
            {{option.id}}
          </:option>
        </SelectGeneric>
      </m.Body>
      <m.Footer>
        <Footer
          @isRunning={{this.isSettlingPayment}}
          @onCancel={{this.closeSettleModal}}
          @onSubmit={{this.settlePayment}}
          @submitText="Settle Payment"
        />
      </m.Footer>
    </Modal>
    <Modal
      @isOpen={{this.cancelModalOpen}}
      @onClose={{this.closeCancelModal}}
      class="min-h-64"
      @backdrop="faded"
      as |m|
    >
      <m.Header>{{t "tuition_assistance.admin.payments.cancel"}}</m.Header>
      <m.Body>
        <SelectGeneric
          @label="Payment Runs"
          @options={{this.settleablePayments}}
          @selected={{this.selectedPayment}}
          @onChange={{this.setSelectedPayment}}
          @searchEnabled={{true}}
          @renderInPlace={{false}}
        >
          <:option as |option|>
            {{safeFormatDate option.paymentDate}}
            -
            {{option.id}}
          </:option>
        </SelectGeneric>
      </m.Body>
      <m.Footer>
        <Footer
          @isRunning={{this.isCancelingPayment}}
          @onCancel={{this.closeCancelModal}}
          @onSubmit={{this.cancelPayment}}
          @submitText="Cancel Payment"
        />
      </m.Footer>
    </Modal>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
};
export default RouteTemplate(AdminTuitionAssistancePayments);
