import { template } from "@ember/template-compiler";
import { and, eq, gt } from 'ember-truth-helpers';
import { fn } from '@ember/helper';
import { on } from '@ember/modifier';
import { t } from 'ember-intl';
import MaterialIcon from 'tio-common/components/material-icon';
import { Divider } from 'tio-ui/components/utilities';
import type { TemplateOnlyComponent as TOC } from '@ember/component/template-only';
import type AccountModel from 'tio-common/models/account';
interface S {
    Args: {
        account: AccountModel;
        selectedAccount: AccountModel | null;
        index: number;
        onClickAccountRow: (account: AccountModel) => void;
        sortedAccounts: AccountModel[];
    };
}
const AccountRow: TOC<S> = template(`
  <button
    class="flex items-center space-x-2 py-6 px-4 border rounded-md border-gray-200 hover:bg-gray-100 shadow-sm cursor-pointer w-full
      {{if (eq @selectedAccount @account) 'border-violet-600 border-2' ''}}"
    type="button"
    {{on "click" (fn @onClickAccountRow @account)}}
  >
    <span class="font-medium">{{@account.institutionName}}</span>
    {{#if (eq @selectedAccount @account)}}
      <MaterialIcon @icon="check_circle" class="text-violet-600 !ml-auto text-md" />
    {{/if}}
  </button>
  {{#if (and (gt @sortedAccounts.length 1) (eq @index 0))}}
    <Divider class="my-3" />
    <span class="text-md text-gray-500 -mb-2">{{t
        "slr.account_dialog.select_different_account"
      }}</span>
  {{/if}}
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default AccountRow;
