import { template } from "@ember/template-compiler";
import { concat } from '@ember/helper';
import { dropTask } from 'ember-concurrency';
import { getSingleValueForTasField } from 'tio-common/utils/tuition-assistance/fields';
import { hasInProgressInstanceOfCurrentProgramTemplate } from 'tio-common/utils/tuition-assistance/tas-grouping';
import { not } from 'ember-truth-helpers';
import { or } from 'ember-truth-helpers';
import { service } from '@ember/service';
import { t } from 'ember-intl';
import { trackedFunction } from 'reactiveweb/function';
import AppPage from 'tio-employee/components/app/page';
import AppPageContent from 'tio-employee/components/app/page/content';
import CardGeneric from 'tio-ui/components/card-generic';
import Component from '@glimmer/component';
import pageTitle from 'ember-page-title/helpers/page-title';
import RouteTemplate from 'ember-route-template';
import TasFaqs from 'tio-common/components/tas/faqs';
import TioAlert from 'tio-common/components/tio/alert';
import TioButton from 'tio-common/components/tio/button';
import TioClickableText from 'tio-common/components/tio/clickable-text';
import TioHeader from 'tio-common/components/tio/page/hero/index';
import TioPageBreadcrumbs from 'tio-common/components/tio/page/breadcrumbs';
import TioPageHeaderTitle from 'tio-common/components/tio/page/header/title';
import type IntlService from 'ember-intl/services/intl';
import type ProgressBuilder from 'tio-common/services/progress-builder';
import type RouterService from '@ember/routing/router-service';
import type SessionContextService from 'tio-employee/services/session-context';
import type StoreService from 'tio-common/services/store';
import type TasGroupModel from 'tio-common/models/tas-group';
import type TasParticipantModel from 'tio-common/models/tas-participant';
import type TASProgramInstanceModel from 'tio-common/models/tas-program-instance';
import type TasProgramTemplate from 'tio-common/models/tas-program-template';
import type TasProgramTemplateModel from 'tio-common/models/tas-program-template';
import type TuitionAssistanceService from 'tio-common/services/tuition-assistance';
import VerticalProgressTracker from 'tio-common/components/tio/vertical-progress-tracker';
import { action } from '@ember/object';
export interface S {
    Args: {
        model: {
            activeInstances: TASProgramInstanceModel[];
            programTemplate: TasProgramTemplate;
            tasParticipant: TasParticipantModel;
            tasGroups: TasGroupModel[];
        };
    };
}
let AuthenticatedTuitionAssistanceProgramOverview = class AuthenticatedTuitionAssistanceProgramOverview extends Component<S> {
    @service
    intl: IntlService;
    @service
    progressBuilder: ProgressBuilder;
    @service
    router: RouterService;
    @service
    sessionContext: SessionContextService;
    @service
    store: StoreService;
    @service
    tuitionAssistance: TuitionAssistanceService;
    get isActiveEligibility() {
        return this.tuitionAssistance.hasActiveEligibilityForProgramTemplate(this.programTemplate);
    }
    get isRestrictedByProgramExclusivity() {
        if (this.args.model.tasGroups.length === 0) {
            return false;
        }
        return hasInProgressInstanceOfCurrentProgramTemplate(this.args.model.tasGroups, this.programTemplate.id, this.args.model.activeInstances);
    }
    get ineligibleBasedOnWaitingPeriod() {
        return this.tuitionAssistance.ineligibleBasedOnWaitingPeriod(this.programTemplate.eligibilityWaitingPeriod, this.args.model.tasParticipant);
    }
    get waitingPeriod() {
        return String(getSingleValueForTasField('ELIGIBILITY_WAITING_PERIOD', this.programTemplate.fields) || 0);
    }
    get dateEligible() {
        const date1 = this.tuitionAssistance.waitingPeriodEndDate(this.programTemplate.eligibilityWaitingPeriod, this.args.model.tasParticipant);
        return this.intl.formatDate(date1, {
            month: 'short',
            day: 'numeric',
            year: 'numeric'
        });
    }
    activeProgramInstancesData = trackedFunction(this, async ()=>{
        const activeInstances1 = await this.store.query('tas-program-instance', {
            filter: {
                employee: this.sessionContext.currentEmployee.id,
                active: true
            },
            include: 'tas-program-template,tas-applications'
        });
        return activeInstances1;
    });
    get activeProgramInstances() {
        return this.activeProgramInstancesData.value || [];
    }
    get isHeldParticipant() {
        return !!this.args.model.tasParticipant.isHeldParticipant;
    }
    get canApplyToProgram() {
        if (this.isHeldParticipant || !this.isActiveEligibility || this.isRestrictedByProgramExclusivity || this.ineligibleBasedOnWaitingPeriod || !this.sessionContext.currentEmployee.id || !this.activeProgramInstancesData.value) {
            return false;
        }
        if (this.activeProgramInstances.length === 0 || this.isMultipleInstanceProgram) {
            return true;
        }
        const templateIds1 = this.activeProgramInstances.map((instance1: TasProgramTemplateModel)=>instance1.tasProgramTemplate.id);
        const hasActiveInstanceForProgram1 = templateIds1.includes(this.programTemplate.id);
        return !hasActiveInstanceForProgram1;
    }
    get instanceForExistingApp() {
        const templateId1 = this.programTemplate.id;
        const instances1 = this.activeProgramInstances;
        const activeInstanceMatchesTemplateId1 = instances1.find((instance1: TasProgramTemplateModel)=>templateId1 === instance1.tasProgramTemplate.id);
        return activeInstanceMatchesTemplateId1;
    }
    get programInstance() {
        return this.instanceForExistingApp || {};
    }
    get programTemplate() {
        return this.args.model.programTemplate;
    }
    get hideProcessDescriptionSteps() {
        return this.programTemplate.hideProcessDescriptionSteps;
    }
    get programSteps() {
        return this.progressBuilder.applicationProcess();
    }
    get isMultipleInstanceProgram() {
        return this.programTemplate.isMultipleInstanceProgram;
    }
    get buttonTextLoaded() {
        return !this.activeProgramInstances?.length;
    }
    get buttonText() {
        const templateIds1 = this.activeProgramInstances.map((instance1: TasProgramTemplateModel)=>instance1.tasProgramTemplate.id);
        const hasActiveInstanceForProgram1 = templateIds1.includes(this.programTemplate.id);
        if (this.isMultipleInstanceProgram && hasActiveInstanceForProgram1) {
            return this.intl.t('tuition_assistance.program_details.create_new_program_instance');
        } else {
            return this.intl.t('tuition_assistance.get_started');
        }
    }
    get isDependentProgram() {
        return this.programTemplate.isDependentProgram;
    }
    // createInstanceAndApply = dropTask(async () => {
    // TAS.todo: This will need to be updated  to use the new types to create the instance
    /*
    // const programTemplate = this.args.model.programTemplate;
    // const instance = this.store.createRecord('tas-program-instance', {
    //   tasProgramTemplate: programTemplate,
    //   employee: this.sessionContext.currentEmployee,
    //   fields: {},
    //   customFields: programTemplate.instanceCustomFields,
    // });
    // instance.setInitialEmployeeFields();

    // await instance.save();

    // if (programTemplate.isCertificateProgram) {
    //   try {
    //     await this.store.adapterFor('tas-program-instance').requestProgramApproval(instance);
    //   } catch (e) {
    //     console.error(e);
    //   }
    //   this.router.transitionTo(
    //     'authenticated.tuition-assistance.programs.instance.certificate.index',
    //     instance.id
    //   );
    // } else {
    //   this.router.transitionTo(
    //     'authenticated.tuition-assistance.programs.instance.apply',
    //     instance.id
    //   );
    // }
    */ // this.router.transitionTo('authenticated.tas.applications.type-two.new');
    // });
    initializeTypeTwoProgram = dropTask(async ()=>{
        const employeeId1 = this.sessionContext.currentEmployee.id;
        // TAS.TODO: Update this to provide correct date backend eligibility check if needed
        const date1 = new Date();
        try {
            console.log('performing initializeTypeTwoProgram');
            const newProgram1 = await this.store.adapterFor('tas-program-template').initializeTypeTwoProgram(this.programTemplate.id, employeeId1, date1);
            console.log('newProgram Received', newProgram1);
            this.router.transitionTo('authenticated.tas.applications.type-two', newProgram1.id);
        } catch (e1) {
            console.error(e1);
        }
    });
    @action
    applyToProgram() {
        const programType1 = this.programTemplate.typeClassification;
        switch(programType1){
            case 'TAS.ProgramType.1':
                console.log('Program Type 1');
                break;
            case 'TAS.ProgramType.2':
                console.log('Program Type 2 switch');
                this.initializeTypeTwoProgram.perform();
                break;
            case 'TAS.ProgramType.3':
                console.log('Program Type 3');
                break;
            case 'TAS.ProgramType.4':
                console.log('Program Type 4');
                break;
            default:
                console.log('default will be the same as programType.4');
        }
    }
    get programFaqs() {
        return this.programTemplate.programFaqs;
    }
    get requiresDateEligibilityCheck() {
        // TAS.TODO: Update this to use backend eligibility check if needed
        return false;
    }
    static{
        template(`
    {{pageTitle
      (concat
        @model.programTemplate.programName " | " (t "tuition_assistance.program_details.default")
      )
    }}
    <AppPage>
      <TioPageHeaderTitle>
        {{t "tuition_assistance.default"}}
      </TioPageHeaderTitle>
      <TioPageBreadcrumbs class="mb-4" as |b|>
        <b.crumb @route="authenticated.tuition-assistance.dashboard" @label={{t "dashboard"}} />
        <b.crumb
          @route="authenticated.tuition-assistance.program-overview"
          @label={{t "tuition_assistance.program_details.default"}}
        />
      </TioPageBreadcrumbs>

      <AppPageContent>
        {{#if @model.tasParticipant.isHeld}}
          <TioAlert @type="warning" @allowDismiss={{false}} class="mb-4">
            <:header>
              <p class="font-bold">
                {{t "tuition_assistance.held_participant_warnings.dashboard_title"}}
              </p>
            </:header>
            <:body>
              <p class="text-sm">
                {{t "tuition_assistance.held_participant_warnings.dashboard_description"}}
              </p>
            </:body>
          </TioAlert>
        {{/if}}

        {{#unless this.isActiveEligibility}}
          <TioAlert @type="warning" @allowDismiss={{false}} class="mb-4">
            <:header>
              <p class="font-bold">
                {{t "tuition_assistance.inactive_participant_warnings.dashboard_title"}}
              </p>
            </:header>
            <:body>
              <p class="text-sm">
                {{t "tuition_assistance.inactive_participant_warnings.dashboard_description"}}
              </p>
            </:body>
          </TioAlert>
        {{/unless}}

        {{#if this.isRestrictedByProgramExclusivity}}
          <TioAlert @type="warning" @allowDismiss={{false}} class="mb-4">
            <:header>
              <p class="font-bold">
                {{t "tuition_assistance.program_exclusivity_warnings.dashboard_title"}}
              </p>
            </:header>
            <:body>
              <p class="text-sm">
                {{t "tuition_assistance.program_exclusivity_warnings.dashboard_description"}}
              </p>
            </:body>
          </TioAlert>
        {{/if}}

        {{#if this.ineligibleBasedOnWaitingPeriod}}
          <TioAlert @type="warning" @allowDismiss={{false}} class="mb-4">
            <:header>
              <p class="font-bold">
                {{t "tuition_assistance.program_eligibility_warnings.dashboard_title"}}
              </p>
            </:header>
            <:body>
              <p class="text-sm">
                {{t
                  "tuition_assistance.program_eligibility_warnings.dashboard_description"
                  time=this.waitingPeriod
                  date=this.dateEligible
                  htmlSafe=true
                }}
              </p>
            </:body>
          </TioAlert>
        {{/if}}

        <div class="flex flex-wrap">
          <div class="grow">
            <div>
              <TioHeader
                @headerBeginning={{@model.programTemplate.programName}}
                @description={{@model.programTemplate.programDescription}}
              />
            </div>

            {{#if this.requiresDateEligibilityCheck}}
              <CardGeneric class="mx-0">
                <:body>
                  <div class="flex my-8">
                    <TioButton
                      @onClick={{this.applyToProgram}}
                      @disabled={{not this.canApplyToProgram}}
                      @loading={{or this.initializeTypeTwoProgram.isRunning this.buttonTextLoaded}}
                      class="uppercase w-60"
                    >
                      {{this.buttonText}}
                    </TioButton>
                  </div>
                  {{#if this.instanceForExistingApp}}
                    <div class="flex text-sm py-2">
                      <span>
                        {{t "tuition_assistance.you_already_have_an_active_program"}}
                      </span>
                      <span class="text-center mx-1">
                        <TioClickableText
                          @linkTo={{if
                            this.isMultipleInstanceProgram
                            "authenticated.tuition-assistance.dashboard"
                            "authenticated.tuition-assistance.programs.instance.index"
                          }}
                          @linkToModel={{unless
                            this.isMultipleInstanceProgram
                            this.instanceForExistingApp
                          }}
                          @textClass="text-sm font-medium text-ocean-600 hover:text-ocean-800 underline"
                        >
                          {{t "click_here"}}
                        </TioClickableText>
                      </span>
                      <span>
                        {{t "tuition_assistance.to_view_program_details"}}
                      </span>
                    </div>
                  {{/if}}
                </:body>
              </CardGeneric>
            {{else}}
              <div class="my-8">
                <TioButton
                  @onClick={{this.applyToProgram}}
                  @disabled={{not this.canApplyToProgram}}
                  @loading={{or this.initializeTypeTwoProgram.isRunning this.buttonTextLoaded}}
                  class="uppercase w-60"
                >
                  {{this.buttonText}}
                </TioButton>
                {{#if this.instanceForExistingApp}}
                  <div class="flex text-sm py-2">
                    <span>
                      {{t "tuition_assistance.you_already_have_an_active_program"}}
                    </span>
                    <span class="text-center mx-1">
                      <TioClickableText
                        @linkTo={{if
                          this.isMultipleInstanceProgram
                          "authenticated.tuition-assistance.dashboard"
                          "authenticated.tuition-assistance.programs.instance.index"
                        }}
                        @linkToModel={{unless
                          this.isMultipleInstanceProgram
                          this.instanceForExistingApp
                        }}
                        @textClass="text-sm font-medium text-ocean-600 hover:text-ocean-800 underline"
                      >
                        {{t "click_here"}}
                      </TioClickableText>
                    </span>
                    <span>
                      {{t "tuition_assistance.to_view_program_details"}}
                    </span>
                  </div>
                {{/if}}

              </div>
            {{/if}}
            <article class="max-w-7xl">
              <TasFaqs @template={{@model.programTemplate}} />
            </article>
          </div>
          {{#unless this.hideProcessDescriptionSteps}}
            <div class="mx-4">
              <CardGeneric @size="sm" class="justify-self-end shrink">
                <:header>
                  <h2 class="text-sm font-semibold mb-4">
                    {{t "tuition_assistance.program_steps.application_process"}}
                  </h2>
                </:header>
                <:body>
                  <VerticalProgressTracker @steps={{this.programSteps}} class="-mb-8 mx-4" />
                </:body>
              </CardGeneric>
            </div>
          {{/unless}}
        </div>
      </AppPageContent>
    </AppPage>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
};
export default RouteTemplate(AuthenticatedTuitionAssistanceProgramOverview);
