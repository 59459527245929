import { template } from "@ember/template-compiler";
import { action } from '@ember/object';
import { array, concat, fn } from '@ember/helper';
import { dropTask } from 'ember-concurrency';
import { eq, or, not } from 'ember-truth-helpers';
import { htmlSafe } from '@ember/template';
import { service } from '@ember/service';
import { t } from 'ember-intl';
import { tracked } from '@glimmer/tracking';
import AppChangelogEditableAddressField from 'tio-common/components/app/changelog-editable/address-field';
import AppChangelogEditableCurrencyField from 'tio-common/components/app/changelog-editable/currency-field';
import AppChangelogEditableDateField from 'tio-common/components/app/changelog-editable/date-field';
import AppChangelogEditableDropdownField from 'tio-common/components/app/changelog-editable/dropdown-field';
import AppChangelogEditableTextField from 'tio-common/components/app/changelog-editable/text-field';
import AppPage from 'tio-employee/components/app/page';
import AppPageContent from 'tio-employee/components/app/page/content';
import Component from '@glimmer/component';
import ConfirmModal from 'tio-common/components/tio/confirm/confirm-modal';
import ContributionsAccountingPayments from 'tio-employee/components/contributions/accounting-payments';
import employeeSchema from 'tio-common/validation-schema/models/employee';
import formatPhone from 'tio-common/helpers/format-phone';
import jsonPrettyPrint from 'tio-common/helpers/json-pretty-print';
import MaterialIcon from 'tio-common/components/material-icon';
import PlanSummary from 'tio-employee/components/plan/summary';
import ProfileFamilyInvites from 'tio-common/components/profile/family-invites';
import ProfileLegacyStrategyFinderResults from 'tio-common/components/profile/legacy-strategy-finder-results';
import ProfileNotificationHistories from 'tio-common/components/profile/notification-histories';
import ProfilePslf from 'tio-common/components/profile/pslf';
import ProfileRecommendations from 'tio-common/components/profile/recommendations';
import ProfileRoleDelgation from 'tio-common/components/profile/role-delegation/delegation';
import RouteTemplate from 'ember-route-template';
import safeFormatDate from 'tio-common/helpers/safe-format-date';
import TermVerified from 'tio-common/components/profile/term-verified';
import TioChangePlan from 'tio-common/components/tio/change-plan';
import TioDescriptionRow from 'tio-common/components/tio/description-row';
import TioExpandableSection from 'tio-common/components/tio/expandable-section';
import TioPageBreadcrumbs from 'tio-common/components/tio/page/breadcrumbs';
import TioPageHeaderTitle from 'tio-common/components/tio/page/header/title';
import TioQuicklinkButton from 'tio-common/components/tio/quicklink-button';
import TioQuicklinks from 'tio-common/components/tio/quicklinks';
import TuitionAssistanceProfileTuitionAssistance from 'tio-employee/components/tuition-assistance/profile/tuition-assistance';
import type ContributionModel from 'tio-common/models/contribution';
import type EmployeeModel from 'tio-common/models/employee';
import type IntlService from 'ember-intl/services/intl';
import type PartnerService from 'tio-employee/services/partner';
import type RouterService from '@ember/routing/router';
import type SessionContextService from 'tio-employee/services/session-context';
import type StoreService from 'tio-common/services/store';
import type StrategyFinderService from 'tio-common/services/strategy-finder';
import UiDetailsBox from 'tio-common/components/ui/details-box';
export interface S {
    Args: {
        model: EmployeeModel;
        plan: ContributionModel;
    };
}
let EmployeeShowRoute = class EmployeeShowRoute extends Component<S> {
    @service
    intl: IntlService;
    @service
    sessionContext: SessionContextService;
    @service
    router: RouterService;
    @service
    strategyFinder: StrategyFinderService;
    @service
    store: StoreService;
    @service
    partner: PartnerService;
    @tracked
    isEditing = false;
    @tracked
    isExpandedSidebar = false;
    @tracked
    showEmployeeMenu = false;
    @tracked
    showActivationModal = false;
    @tracked
    changelog = '';
    @tracked
    showPlans = false;
    @tracked
    submitError?: unknown;
    @tracked
    hasSubmitted: boolean = false;
    get stateOptions() {
        // @ts-expect-error: what is this?
        return this.fetchedStates.value || [];
    }
    get isNotCustomPlan() {
        if (this.args.model.plan?.tiers.length) {
            return this.args.model.plan.tiers[0]?.['override-amount'] !== 'EMPLOYEE_AMOUNT';
        }
        return true;
    }
    get employeeStatus() {
        if (this.args.model.isActive) {
            return 'Active';
        } else if (this.args.model.deactivatedOn) {
            return 'Deactivated';
        } else {
            return '-';
        }
    }
    get birthYears() {
        const currentYear1 = new Date().getFullYear(), years1 = [];
        let startYear1 = 1920;
        while(startYear1 <= currentYear1){
            years1.push(startYear1++);
        }
        return years1.reverse();
    }
    @action
    goToPlans() {
        this.router.transitionTo('authenticated.admin.plans', this.args.model.company.id);
    }
    @action
    toggleShowPlans() {
        this.showPlans = !this.showPlans;
    }
    @action
    toggleActivationModal() {
        this.showEmployeeMenu = false;
        this.showActivationModal = !this.showActivationModal;
    }
    @action
    toggleEmployeeMenu() {
        this.showEmployeeMenu = !this.showEmployeeMenu;
    }
    @action
    toggleSidebar() {
        this.isExpandedSidebar = !this.isExpandedSidebar;
    }
    saveEmployeeWithChangelog = dropTask(async (attrName1, updatedValue1, changelog1 = '')=>{
        this.args.model[attrName1] = updatedValue1;
        await this.args.model.save({
            adapterOptions: {
                changelog: changelog1
            }
        });
    });
    saveEmployeeAddressWithChangelog = dropTask(async (updatedValues1, changelog1 = '')=>{
        this.args.model['addressLine1'] = updatedValues1['addressLine1'];
        this.args.model['addressLine2'] = updatedValues1['addressLine2'];
        this.args.model['city'] = updatedValues1['city'];
        this.args.model['state'] = updatedValues1['state'];
        this.args.model['postalCode'] = updatedValues1['postalCode'];
        await this.args.model.save({
            adapterOptions: {
                changelog: changelog1
            }
        });
    });
    saveEmployeeStatusWithChangelog = dropTask(async (updatedValues1, changelog1 = '')=>{
        updatedValues1['isActive'] == 'Active' ? (this.args.model['isActive'] = true) : (this.args.model['isActive'] = false);
        await this.args.model.save({
            adapterOptions: {
                changelog: changelog1
            }
        });
    });
    saveEmployeeCustomAttributesWithChangelog = dropTask(async (attrName1, updatedValue1, changelog1 = '')=>{
        // @ts-expect-error: what is this?
        this.args.model.customAttributes[attrName1] = updatedValue1;
        await this.args.model.save({
            adapterOptions: {
                changelog: changelog1
            }
        });
    });
    get serverErrorMessages() {
        const genericErrorMessage1 = this.intl.t('error_occurred');
        // @ts-expect-error: errors is not typed
        if (this.submitError?.errors) {
            // @ts-expect-error: errors is not typed
            return this.submitError.errors.map((err1)=>{
                return err1?.detail || genericErrorMessage1;
            });
        } else if (this.submitError) {
            return [
                genericErrorMessage1
            ];
        }
        return [];
    }
    deactivateOrReactivateEmployee = dropTask(async ()=>{
        const employee1 = this.args.model;
        this.submitError = null;
        this.hasSubmitted = true;
        try {
            if (employee1.participationStatus === 'participating' || employee1.participationStatus === 'pending') {
                await this.store.adapterFor('employee').terminateEmployee(employee1, {
                    changelog: this.changelog
                });
            } else if (employee1.participationStatus === 'deactivated') {
                await this.store.adapterFor('employee').reinstateEmployee(employee1, {
                    changelog: this.changelog
                });
            }
            this.showActivationModal = false;
            this.changelog = '';
        } catch (err1) {
            this.submitError = err1;
            console.error(err1);
        }
    });
    static{
        template(`
    <AppPage>
      <TioPageBreadcrumbs as |b|>
        <b.crumb
          class="!relative !inline-block"
          @route="authenticated.admin.employees.index"
          @label="Employees"
        />
        <b.crumb
          class="!relative !inline-block"
          @route="authenticated.admin.employees.show"
          @label={{@model.fullName}}
        />
      </TioPageBreadcrumbs>
      <AppPageContent>
        <TioPageHeaderTitle>
          <div class="inline-block">
            <h1 class="text-2xl inline-block">
              <span class="text-gray-800">{{t "partner.employee.employee_details"}}</span>
              <TioQuicklinks
                @direction="right"
                @show={{this.showEmployeeMenu}}
                @toggle={{this.toggleEmployeeMenu}}
              >
                <TioQuicklinkButton
                  @isDisabled={{if
                    (or
                      (eq @model.participationStatus "pending")
                      (eq @model.participationStatus "participating")
                    )
                    true
                    false
                  }}
                  @buttonText={{t "partner.employee.reactivate"}}
                  @method={{this.toggleActivationModal}}
                />
                <br />
                <TioQuicklinkButton
                  @isDisabled={{if
                    (or
                      (eq @model.participationStatus "pending")
                      (eq @model.participationStatus "participating")
                    )
                    false
                    true
                  }}
                  @buttonText={{t "partner.employee.deactivate"}}
                  @method={{this.toggleActivationModal}}
                />
                <TioQuicklinkButton
                  @buttonText={{t "plans.edit_plan"}}
                  @method={{this.toggleShowPlans}}
                  @isDisabled={{false}}
                />
                <br />
              </TioQuicklinks>
              <br />

            </h1>
          </div>
        </TioPageHeaderTitle>
        <br />
        <br />
        <ConfirmModal
          @show={{this.showActivationModal}}
          @cancel={{this.toggleActivationModal}}
          @submitAction={{this.deactivateOrReactivateEmployee}}
          @submitButtonText={{if
            (or
              (eq @model.participationStatus "pending")
              (eq @model.participationStatus "participating")
            )
            (t "partner.employee.deactivate")
            (t "partner.employee.reactivate")
          }}
          @modalBodyText={{if
            (or
              (eq @model.participationStatus "pending")
              (eq @model.participationStatus "participating")
            )
            (t "partner.employee.deactivate_text")
            (t "partner.employee.reactivate_text")
          }}
          @changelog={{this.changelog}}
          @hasSubmitted={{this.hasSubmitted}}
          @serverErrorMessages={{this.serverErrorMessages}}
        />
        <TioChangePlan
          @close={{this.toggleShowPlans}}
          @show={{this.showPlans}}
          {{! @glint-expect-error: this could be a bug. models are getting mixed }}
          @changelog={{@changelog}}
          @employee={{@model}}
        />

        <div class="relative display-inline-box grid grid-cols-12">
          <UiDetailsBox
            @details={{@model.statistics.user.total_accounts}}
            @detailsLabel={{t "partner.employee.total_accounts"}}
            class="col-span-2"
          />
          <UiDetailsBox
            @details={{@model.statistics.user.total_loans}}
            @detailsLabel={{t "partner.employee.total_loans"}}
            class="col-span-2"
          />
          <UiDetailsBox
            @details={{@model.participationStatus}}
            @detailsLabel={{t "partner.employee.status"}}
            class="col-span-2 capitalize"
          />
          <UiDetailsBox
            @details={{concat @model.plan.companyKey " - " @model.plan.name}}
            @detailsLabel={{t "partner.employee.plan_details"}}
            @goToPlans={{this.goToPlans}}
            class="col-span-2"
          />
        </div>
        <hr class="my-4" />
        <div class="mb-4">
          <h3 class="text-lg font-semibold truncate text-left">
            {{t "partner.employee.employee_profile"}}</h3>
          <div>
            <span class="text-sm text-gray-400">
              <MaterialIcon @icon="info" class="text-sm top-5" />
              {{t "partner.employee.alert_info" htmlSafe=true}}
            </span>
          </div>
          <div class="bg-white border border-gray-200 px-2">
            <TioDescriptionRow @cols="3" class="border-b" as |row|>
              <row.cell @title={{t "partner.partner"}}>
                <:value>
                  {{@model.company.name}}
                </:value>
              </row.cell>
              <row.cell @title={{t "partner.employee.employee_status"}}>
                <:value>
                  {{#if (eq @model.participationStatus "participating")}}
                    <span>{{t "partner.employee.enrolled_on"}}</span>
                    <span class="text-gray-500 ml-1">
                      {{safeFormatDate @model.user.createdAt}}
                    </span><br />
                  {{else if (eq @model.participationStatus "pending")}}
                    <span>{{t "partner.employee.created_on"}}</span>
                    <span class="text-gray-500 ml-1">
                      {{safeFormatDate @model.createdAt}}
                    </span><br />
                  {{else}}
                    <span>{{t "partner.employee.deactivated_on"}}</span>
                    <span class="text-gray-500 ml-1">
                      {{safeFormatDate
                        (if @model.deactivatedOn @model.deactivatedOn @model.updatedAt)
                      }}
                    </span><br />
                  {{/if}}
                </:value>
              </row.cell>
              <row.cell @title={{t "partner.employee.payroll_id"}}>
                <:value>
                  <AppChangelogEditableTextField
                    @ariaLabel=""
                    class="text-md"
                    @value={{@model.payrollId}}
                    @onSave={{fn this.saveEmployeeWithChangelog.perform "payrollId"}}
                    @validationSchema={{employeeSchema.fields.payrollId}}
                  />
                </:value>
              </row.cell>
              <row.cell @title={{t "partner.employee.first_name"}}>
                <:value>
                  <AppChangelogEditableTextField
                    @ariaLabel=""
                    class="text-md"
                    @value={{@model.firstName}}
                    @onSave={{fn this.saveEmployeeWithChangelog.perform "firstName"}}
                    @validationSchema={{employeeSchema.fields.firstName}}
                  />
                </:value>
              </row.cell>
              <row.cell @title={{t "partner.employee.last_name"}}>
                <:value>
                  <AppChangelogEditableTextField
                    @ariaLabel=""
                    class="text-md"
                    @value={{@model.lastName}}
                    @onSave={{fn this.saveEmployeeWithChangelog.perform "lastName"}}
                    @validationSchema={{employeeSchema.fields.lastName}}
                  />
                </:value>
              </row.cell>
              <row.cell @title={{t "partner.employee.employment_type"}}>
                <:value>
                  <AppChangelogEditableDropdownField
                    @ariaLabel=""
                    class="text-md"
                    @value={{@model.employmentType}}
                    @options={{array "" "FULL_TIME" "PART_TIME" "EXEMPT" "NON_EXEMPT"}}
                    @onSave={{fn this.saveEmployeeWithChangelog.perform "employmentType"}}
                    @validationSchema={{employeeSchema.fields.employmentType}}
                  />
                </:value>
              </row.cell>
              <row.cell @title={{t "partner.employee.birth_year"}}>
                <:value>
                  <AppChangelogEditableDropdownField
                    @ariaLabel=""
                    class="text-md"
                    @value={{@model.birthYear}}
                    @options={{this.birthYears}}
                    @onSave={{fn this.saveEmployeeWithChangelog.perform "birthYear"}}
                    @validationSchema={{employeeSchema.fields.birthYear}}
                  />
                </:value>
              </row.cell>
              <row.cell @title={{t "partner.employee.phone_number"}}>
                <:value>
                  <AppChangelogEditableTextField
                    @ariaLabel=""
                    class="text-md"
                    @value={{formatPhone @model.phoneNumber}}
                    @onSave={{fn this.saveEmployeeWithChangelog.perform "phoneNumber"}}
                    @validationSchema={{employeeSchema.fields.phoneNumber}}
                  />
                </:value>
              </row.cell>
            </TioDescriptionRow>
            <TioDescriptionRow @cols="3" class="border-b" as |row|>
              <row.cell @title={{t "partner.employee.address"}}>
                <:value>
                  <AppChangelogEditableAddressField
                    class="text-md"
                    @value={{@model}}
                    @onSave={{this.saveEmployeeAddressWithChangelog.perform}}
                    @ariaLabel="Editable Address Field"
                  />
                </:value>
              </row.cell>
              <row.cell @title={{t "partner.employee.email"}}>
                <:value>
                  <AppChangelogEditableTextField
                    @ariaLabel=""
                    class="text-md"
                    @value={{@model.email}}
                    @onSave={{fn this.saveEmployeeWithChangelog.perform "email"}}
                    @validationSchema={{employeeSchema.fields.email}}
                  />
                </:value>
              </row.cell>
              <row.cell @title={{t "partner.employee.employment_start_date"}}>
                <:value>
                  <AppChangelogEditableDateField
                    @value={{or
                      (safeFormatDate
                        @model.employmentStartDate
                        month="long"
                        day="2-digit"
                        year="numeric"
                        timeZone="UTC"
                      )
                      ""
                    }}
                    @onSave={{fn this.saveEmployeeWithChangelog.perform "employmentStartDate"}}
                    @validationSchema={{employeeSchema.fields.employmentStartDate}}
                    @ariaLabel="Editable Date Field"
                  />
                </:value>
              </row.cell>
              <row.cell
                @title={{t "partner.employee.employment_end_date"}}
                @value={{or @model.employmentEndDate (t "current")}}
              >
                <:value>
                  <AppChangelogEditableDateField
                    @value={{or
                      (safeFormatDate
                        @model.employmentEndDate
                        month="long"
                        day="2-digit"
                        year="numeric"
                        timeZone="UTC"
                      )
                      ""
                    }}
                    @onSave={{fn this.saveEmployeeWithChangelog.perform "employmentEndDate"}}
                    @validationSchema={{employeeSchema.fields.employmentEndDate}}
                    @ariaLabel="Editable Date Field"
                  />
                </:value>
              </row.cell>
              <row.cell @title={{t "partner.employee.salary"}}>
                <:value>
                  <AppChangelogEditableCurrencyField
                    @value={{@model.salary}}
                    @onSave={{fn this.saveEmployeeWithChangelog.perform "salary"}}
                    @validationSchema={{employeeSchema.fields.salary}}
                  />
                </:value>
              </row.cell>
              <row.cell @title={{t "partner.employee.rate_period"}}>
                <:value>
                  <AppChangelogEditableDropdownField
                    @ariaLabel=""
                    class="text-md"
                    @value={{@model.ratePeriod}}
                    @options={{array "HOURLY" "WEEKLY" "BI_WEEKLY" "MONTHLY" "YEARLY"}}
                    @onSave={{fn this.saveEmployeeWithChangelog.perform "ratePeriod"}}
                    @validationSchema={{employeeSchema.fields.ratePeriod}}
                  />
                </:value>
              </row.cell>
            </TioDescriptionRow>
            <TioDescriptionRow @cols="3" class="border-b" as |row|>
              <row.cell @title={{t "partner.employee.title"}}>
                <:value>
                  <AppChangelogEditableTextField
                    @ariaLabel=""
                    class="text-md"
                    @value={{@model.title}}
                    @onSave={{fn this.saveEmployeeWithChangelog.perform "title"}}
                    @validationSchema={{employeeSchema.fields.title}}
                  />
                </:value>
              </row.cell>
              <row.cell @title={{t "partner.employee.department"}}>
                <:value>
                  <AppChangelogEditableTextField
                    @ariaLabel=""
                    class="text-md"
                    @value={{@model.department}}
                    @onSave={{fn this.saveEmployeeWithChangelog.perform "department"}}
                    @validationSchema={{employeeSchema.fields.department}}
                  />
                </:value>
              </row.cell>
              <row.cell @title={{t "partner.employee.cost_center"}}>
                <:value>
                  <AppChangelogEditableTextField
                    @ariaLabel=""
                    class="text-md"
                    @value={{@model.costCenter}}
                    @onSave={{fn this.saveEmployeeWithChangelog.perform "costCenter"}}
                    @validationSchema={{employeeSchema.fields.costCenter}}
                  />
                </:value>
              </row.cell>
              <row.cell @title={{t "partner.employee.eligibility_start_date"}}>
                <:value>
                  <AppChangelogEditableDateField
                    @value={{or
                      (safeFormatDate
                        @model.eligibilityStartDate
                        month="long"
                        day="2-digit"
                        year="numeric"
                        timeZone="UTC"
                      )
                      ""
                    }}
                    @onSave={{fn this.saveEmployeeWithChangelog.perform "eligibilityStartDate"}}
                    @validationSchema={{employeeSchema.fields.eligibilityStartDate}}
                    @ariaLabel="Editable Date Field"
                  />
                </:value>
              </row.cell>
              <row.cell @title={{t "partner.employee.eligibility_end_date"}}>
                <:value>
                  <AppChangelogEditableDateField
                    @value={{or
                      (safeFormatDate
                        @model.eligibilityEndDate
                        month="long"
                        day="2-digit"
                        year="numeric"
                        timeZone="UTC"
                      )
                      ""
                    }}
                    @onSave={{fn this.saveEmployeeWithChangelog.perform "eligibilityEndDate"}}
                    @validationSchema={{employeeSchema.fields.eligibilityEndDate}}
                    @ariaLabel="Editable Date Field"
                  />
                </:value>
              </row.cell>
              <row.cell @title={{t "partner.employee.contribution_amount"}}>
                <:value>
                  <AppChangelogEditableCurrencyField
                    @value={{@model.contributionAmount}}
                    @onSave={{fn this.saveEmployeeWithChangelog.perform "contributionAmount"}}
                    @disabled={{this.isNotCustomPlan}}
                    @validationSchema={{employeeSchema.fields.contributionAmount}}
                  />
                </:value>
              </row.cell>
            </TioDescriptionRow>
            <TioDescriptionRow @cols="3" class="border-b" as |row|>
              <row.cell>
                <:value>
                  <TermVerified @employee={{@model}} />
                </:value>
              </row.cell>
            </TioDescriptionRow>
            <p class="p-2 uppercase leading-loose text-gray-500 font-semibold text-xs">
              {{t "partner.employee.custom_partner_data"}}
            </p>
            {{#if @model.customAttributes}}
              {{#each-in @model.customAttributes as |key value|}}
                {{#if
                  (not
                    (or
                      (eq key "employee-hash")
                      (eq key "hash")
                      (eq key "fields")
                      (eq key "hash-keys")
                    )
                  )
                }}
                  <TioExpandableSection @isOpen={{false}} class="p-2">
                    <:header>
                      <h3 class="text-md font-semibold truncate text-left">{{key}}</h3>
                    </:header>
                    <:content>
                      <pre class="border px-2">
                        {{htmlSafe (jsonPrettyPrint value)}}
                      </pre>
                    </:content>
                  </TioExpandableSection>
                {{/if}}
              {{/each-in}}
            {{else}}
              <div class="p-2 text-gray-500">{{t "employees.no_employee_record"}}</div>
            {{/if}}
          </div>
        </div>
        <div class="bg-white mb-4">
          <TioExpandableSection @isOpen={{true}}>
            <:header>
              <h3 class="text-lg font-semibold truncate text-left">
                {{t "partner.employee.payment_transactions"}}
              </h3>
            </:header>
            <:content>
              <ContributionsAccountingPayments @employee={{@model}} as |payments|>
                {{! @glint-expect-error: not sure}}
                <payments.table class="w-full" />
                {{! @glint-expect-error: not sure}}
                {{#if payments.paymentsData.length}}
                  <div class="w-full text-center text-sm p-6 text-gray-600">
                    {{! @glint-expect-error: not sure}}
                    {{payments.paymentsData.length}}
                    {{t "of"}}
                    {{! @glint-expect-error: not sure}}
                    {{payments.paymentsData.length}}
                    {{t "contributions.payments"}}
                  </div>
                {{/if}}
              </ContributionsAccountingPayments>
            </:content>
          </TioExpandableSection>
        </div>

        <div class="bg-white mb-4">
          <PlanSummary
            {{! @glint-expect-error: this could be a bug. models are getting mixed }}
            @changelog={{@changelog}}
            @employee={{@model}}
            @plan={{@plan}}
            {{! @glint-expect-error: this could be a bug. models are getting mixed }}
            @model={{@model}}
            @isOpen={{this.showPlans}}
            id="plan"
          />
        </div>

        <div class="bg-white mb-4">
          <ProfileRecommendations @user={{@model.user}} @recommendationType="STRATEGY_FINDER" />
        </div>

        <div class="bg-white mb-4">
          <ProfileLegacyStrategyFinderResults id="strategy_finders" @employee={{@model}} />
        </div>

        <div class="bg-white mb-4">
          <ProfilePslf id="pslf_forms" @employee={{@model}} @isPartner={{true}} />
        </div>

        <div class="bg-white mb-4">
          <ProfileRoleDelgation @employee={{@model}} />
        </div>

        <TuitionAssistanceProfileTuitionAssistance
          id="tuition-assistance"
          @employee={{@model}}
          class="mb-4"
        />

        <div class="bg-white mb-4">
          <ProfileNotificationHistories id="emails" @employee={{@model}} @user={{@model.user}} />
        </div>

        {{#if @model.company.companySetting.allowFamilyPlan}}
          <div class="bg-white mb-4">
            <ProfileFamilyInvites id="family" @employees={{@model.employees}} />
          </div>
        {{/if}}
      </AppPageContent>
    </AppPage>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
};
export default RouteTemplate(EmployeeShowRoute);
