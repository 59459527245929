import { service } from '@ember/service';
import { setSingleValueForTasField } from 'tio-common/utils/tuition-assistance/fields';
import Route from '@ember/routing/route';
import type { IntlService } from 'ember-intl';
import type RouterService from '@ember/routing/router';
import type StoreService from 'tio-common/services/store';

export default class AuthenticatedTasApplicationsTypeTwoNewRoute extends Route {
  @service declare store: StoreService;
  @service declare router: RouterService;
  @service declare intl: IntlService;
  // @service declare tuitionAssistance: TuitionAssistanceService;

  includes = [
    'tas-courses',
    'tas-courses.tas-assets',
    'tas-program-instance',
    'tas-program-instance.tas-program-template',
    'tas-program-instance.tas-program-template.conditions.public-assets',
    'tas-program-instance.tas-program-template.tas-field-options',
    'tas-program-instance.employee.person',
    'tas-program-instance.employee.tas-participant',
    'tas-assets',
    'tas-assistances',
    'tags',
  ];

  async model({ tas_application_id }: { tas_application_id: string }) {
    const application = await this.store.findRecord('tas-application', tas_application_id, {
      include: this.includes.join(','),
      reload: true,
    });
    // Will need to double check if loading of the tasParticipant is necessary
    // like we have to do in the other routes
    // await this.tuitionAssistance.loadEligibilitiesForCurrentEmployee();
    const instance = application.tasProgramInstance;

    // Set fields for application and instance if empty
    if (Object.keys(application.fields).length === 0) {
      const programTemplate = application.tasProgramInstance.tasProgramTemplate;
      application.fields = programTemplate.applicationFieldsHash;
      application.customFields = programTemplate.applicationCustomFields;

      //set fields on instance
      instance.setInitialEmployeeFields();

      if (instance.isDependentProgram) {
        instance.setInitialDependentFields();
      }

      const displayName = this.intl.formatDate(Date.now(), {
        month: 'long',
        year: 'numeric',
      });
      setSingleValueForTasField('APPLICATION_NAME', displayName, application.fields);

      // Create and associate the course
      const course = await this.store.createRecord('tas-course', {
        tasApplication: application,
        fields: programTemplate.courseFieldsHash,
        customFields: programTemplate.courseCustomFields,
      });

      try {
        application.tasCourses.push(course);
        await application.save();
        await instance.save();
        await course.save();
      } catch (error) {
        console.error('Failed to save application, instance, or course:', error);
        application.rollbackAttributes();
        instance.rollbackAttributes();
        course.rollbackAttributes();
      }
    }

    return { application: application, instance: instance, courses: application.tasCourses };
  }
}
