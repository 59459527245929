import { template } from "@ember/template-compiler";
import { Input, Textarea } from 'tio-ui/components/forms';
import { on } from '@ember/modifier';
import { t } from 'ember-intl';
import Component from '@glimmer/component';
import Drawer from 'tio-ui/components/drawer';
interface AddEducationDrawerSignature {
    Args: {
        isOpen: boolean;
        onClose: () => void;
    };
    Element: HTMLDivElement;
}
export default class AddEducationDrawerComponent extends Component<AddEducationDrawerSignature> {
    inputClasses = {
        base: 'w-full mb-6'
    };
    static{
        template(`
    <Drawer @isOpen={{@isOpen}} @onClose={{@onClose}}>
      <div class="p-6 bg-gray-50">
        <h2 class="text-gray-600 font-semibold">{{t
            "add_education_drawer.add_education_information"
          }}</h2>
        <p class="text-gray-400 text-md">{{t "add_education_drawer.fill_out_education_below"}}</p>
      </div>
      <div class="flex py-4 px-6">
        <div class="my-4">
          <h1 class="text-grey-600 text-2xl">{{t "add_education_drawer.education_information"}}</h1>
          <p>
            <span class="text-error-400 text-xl">*</span>
            {{t "add_education_drawer.required_fields"}}</p>
        </div>
      </div>
      <div class="flex flex-col py-4 px-6 w-full border-b border-gray-200 mt-8">
        <label for="add_education" class="font-semibold text-gray-600">
          {{t "add_education_drawer.add_education_label"}}
          <span class="text-error-400">*</span>
        </label>
        <Input id="add_education" @classes={{this.inputClasses}} />
      </div>
      <div class="flex flex-col py-4 px-6 w-full border-b border-gray-200 mt-12">
        <label for="reason" class="font-semibold text-gray-600">
          {{t "add_education_drawer.reason_label"}}
          <span class="text-error-400">*</span>
        </label>
        <Textarea id="reason" @classes={{this.inputClasses}} />
      </div>
      <div class="flex flex-col py-4 px-6 w-full border-b border-gray-200 mt-8">
        <label for="reimbursement" class="font-semibold text-gray-600">
          {{t "add_education_drawer.name_of_reimbursement"}}
        </label>
        <Input id="reimbursement" @classes={{this.inputClasses}} />
      </div>
      <div class="flex flex-col py-4 px-6 w-full border-b border-gray-200 mt-8">
        <label for="start_date" class="font-semibold text-gray-600">
          {{t "add_education_drawer.start_date_label"}}
          <span class="text-error-400">*</span></label>
        <Input id="start_date" @classes={{this.inputClasses}} @type="date" />
      </div>
      <div class="mt-8 flex justify-end">
        <button
          type="button"
          class="flex py-3 px-4 me-2 mb-2 font-medium text-lg text-gray-800 focus:outline-none bg-white rounded-lg hover:bg-gray-50 hover:border hover:border-black"
          {{on "click" @onClose}}
        >
          {{t "cancel"}}
        </button>
        <button
          type="submit"
          class="flex py-3 px-4 me-2 mb-2 font-medium text-lg text-white focus:outline-none bg-ocean-600 rounded-lg border border-ocean-600 hover:bg-ocean-50 hover:text-ocean-800"
        >
          {{t "save"}}
        </button>
      </div>
    </Drawer>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
