import { template } from "@ember/template-compiler";
import Component from '@glimmer/component';
import type { ComponentLike } from '@glint/template';
import type { TemplateOnlyComponent as TOC } from '@ember/component/template-only';
import { service } from '@ember/service';
import { LinkTo } from '@ember/routing';
import RouterService from '@ember/routing/router-service';
import { hash } from '@ember/helper';
import Trash from 'ember-static-heroicons/components/outline-24/trash';
import ChevronLeft from 'ember-static-heroicons/components/outline-24/chevron-left';
const eq = (a1: unknown, b1: unknown): boolean =>a1 === b1;
const rejectIconType = (type1: string): void =>{
    throw new Error(`no heroicon matching @type ${type1} imported in TasPageHeader`);
};
interface TioPageHeaderHeadingSignature {
    Blocks: {
        default: [];
    };
    Element: HTMLElement;
}
const TioPageHeaderHeading: TOC<TioPageHeaderHeadingSignature> = template(`
  <h2 class="tio-h2 mb-0 text-midnight" ...attributes>{{yield}}</h2>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
interface TioPageHeaderBackLinkSignature {
    Args: {
        route: string;
        label: string;
    };
    Blocks: {
        default: [];
    };
}
const TioPageHeaderBackLink: TOC<TioPageHeaderBackLinkSignature> = template(`
  <LinkTo @route={{@route}} class="align-bottom text-gray-900 font-medium">
    <ChevronLeft class="w-5 inline" />
    {{@label}}
    {{#unless @label}}
      <h5 class="tio-h4 mb-0 inline text-midnight">{{yield}}</h5>
    {{/unless}}
  </LinkTo>
  {{#if @label}}
    <h5 class="tio-h4 mb-0 inline ml-8 text-midnight">{{yield}}</h5>
  {{/if}}
`, {
    eval () {
        return eval(arguments[0]);
    }
});
interface TasPageHeaderActionButtonSignature {
    Blocks: {
        default: [];
    };
    Element: HTMLButtonElement;
}
const buttonClasses = 'rounded-md bg-white text-sm px-3 py-2 font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50';
const TasPageHeaderActionButton: TOC<TasPageHeaderActionButtonSignature> = template(`
  <button type="button" class={{buttonClasses}} ...attributes>
    {{yield}}
  </button>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
// TODO: unionize this as needed
type HeroIconType = 'trash';
interface TasPageHeaderActionIconButtonSignature {
    Args: {
        type: HeroIconType;
    };
    Element: HTMLButtonElement;
}
const iconButtonClasses = 'px-1 py-2 align-bottom text-gray-900 hover:text-gray-600';
const TasPageHeaderActionIconButton: TOC<TasPageHeaderActionIconButtonSignature> = template(`
  <button type="button" class={{iconButtonClasses}} ...attributes>
    {{#if (eq @type "trash")}}
      <Trash class="w-6" />
    {{else}}
      {{rejectIconType @type}}
    {{/if}}
  </button>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
interface TasPageHeaderSignature {
    Element: HTMLElement;
    Blocks: {
        title: [{
                Heading: ComponentLike<TioPageHeaderHeadingSignature>;
                BackLink: ComponentLike<TioPageHeaderBackLinkSignature>;
            }];
        actions: [{
                Button: ComponentLike<TasPageHeaderActionButtonSignature>;
                IconButton: ComponentLike<TasPageHeaderActionIconButtonSignature>;
            }];
    };
}
export default class TasPageHeader extends Component<TasPageHeaderSignature> {
    @service
    router: RouterService;
    static{
        template(`
    <div class="flex w-full items-center">
      {{yield (hash Heading=TioPageHeaderHeading BackLink=TioPageHeaderBackLink) to="title"}}
      <div class="ml-auto">
        {{yield
          (hash Button=TasPageHeaderActionButton IconButton=TasPageHeaderActionIconButton)
          to="actions"
        }}
      </div>
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
