import { template } from "@ember/template-compiler";
import { action } from '@ember/object';
import { dropTask } from 'ember-concurrency';
import { fn } from '@ember/helper';
import { not } from 'ember-truth-helpers';
import { on } from '@ember/modifier';
import { service } from '@ember/service';
import { t } from 'ember-intl';
import { tracked } from '@glimmer/tracking';
import Component from '@glimmer/component';
import EmailLookupWrapper from 'tio-employee/components/auth/email-lookup-wrapper';
import emailSchema from 'tio-common/validation-schema/models/email';
import FormInput from '@frontile/forms-legacy/components/form-input';
import RouteTemplate from 'ember-route-template';
import TioButton from 'tio-common/components/tio/button';
import TioEmbedVue from 'tio-common/components/tio/embed-vue';
import type ForgotPasswordController from 'tio-employee/controllers/forgot-password';
import type RouterService from '@ember/routing/router-service';
import type StoreService from 'tio-common/services/store';
interface ForgotPasswordRouteComponentSignature {
    Args: {
        controller: ForgotPasswordController;
    };
}
let ForgotPasswordRoute = class ForgotPasswordRoute extends Component<ForgotPasswordRouteComponentSignature> {
    @service
    store: StoreService;
    @service
    router: RouterService;
    @tracked
    email = '';
    @tracked
    isLoading = false;
    constructor(owner1: unknown, args1: ForgotPasswordRouteComponentSignature['Args']){
        super(owner1, args1);
        this.email = this.emailFromQueryParams;
    }
    get isEmberEnabled() {
        return this.args.controller['ember-forgot-password'] === '1';
    }
    get validationErrors() {
        try {
            // @ts-expect-error: same as email-field.gts
            emailSchema.fields.email?.validateSync?.(this.email);
        } catch (err1) {
            return err1.errors || [];
        }
        return [];
    }
    get emailFromQueryParams() {
        const urlParams1 = new URLSearchParams(window.location.search);
        return decodeURIComponent(urlParams1.get('email') || '');
    }
    sendPasswordEmail = dropTask(async ()=>{
        try {
            await this.store.adapterFor('login').sendForgotPassword({
                email: this.email,
                requestType: 'EMPLOYEE'
            });
        } catch (e1) {
            console.error(e1);
        } finally{
            this.isLoading = false;
            this.router.transitionTo('forgot-password.confirmation');
        }
    });
    @action
    async submitForgotPassword() {
        this.isLoading = true;
        if (this.validationErrors.length) return;
        this.sendPasswordEmail.perform();
    }
    static{
        template(`
    <div class="flex flex-col h-screen container mx-auto w-full overflow-auto">
      <TioEmbedVue @route="/forgot-password" @disabled={{this.isEmberEnabled}}>
        <EmailLookupWrapper @disabled={{not this.isEmberEnabled}} @route="forgot-password">
          <h1 class="text-xl my-4 text-center">{{t "login.forgot_password_question"}}</h1>
          <p class="mb-4">{{t "login.forgot_password_body"}}</p>
          <form {{on "submit" this.submitForgotPassword}}>
            <div class="mb-10">
              <FormInput
                @label={{t "login.email"}}
                @value={{this.email}}
                @onInput={{fn (mut this.email)}}
                @errors={{this.validationErrors}}
                @type="email"
              />
            </div>
            <div class="flex justify-center items-center space-x-4 mb-10">
              <TioButton @outlined={{true}} @linkTo="login" class="w-36">
                {{t "login.back_to_login"}}
              </TioButton>
              <TioButton
                @loading={{this.isLoading}}
                @onClick={{this.submitForgotPassword}}
                class="w-36"
              >
                {{t "submit"}}
              </TioButton>
            </div>
          </form>
        </EmailLookupWrapper>
      </TioEmbedVue>
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
};
export default RouteTemplate(ForgotPasswordRoute);
