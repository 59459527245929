import { template } from "@ember/template-compiler";
import type PslfFormModel from 'tio-common/models/pslf-form';
import Component from '@glimmer/component';
import RouteTemplate from 'ember-route-template';
import AppPage from 'tio-employee/components/app/page';
import TioPageHeaderTitle from 'tio-common/components/tio/page/header/title';
import AppPageContent from 'tio-employee/components/app/page/content';
import type RouterService from '@ember/routing/router-service';
import { service } from '@ember/service';
import { t } from 'ember-intl';
import type { IntlService } from 'ember-intl';
interface AdminPslfFormSuccessRouteSignature {
    Args: {
        model: PslfFormModel;
    };
}
let AdminPslfFormSuccessRoute = class AdminPslfFormSuccessRoute extends Component<AdminPslfFormSuccessRouteSignature> {
    @service
    intl: IntlService;
    @service
    router: RouterService;
    get borrowerName() {
        return this.args.model.borrowerFields['borrower-name'];
    }
    get employerMessage() {
        return this.intl.t('pslf.complete_pslf.done_employer_subheader', {
            date: this.intl.formatDate(this.args.model.employerCompletedAt),
            name: this.borrowerName
        });
    }
    static{
        template(`
    <AppPage>
      <TioPageHeaderTitle>
        {{t "pslf.default_full"}}
      </TioPageHeaderTitle>
      <AppPageContent>
        <div class="max-w-4xl mt-6">
          <h1 class="text-2xl font-semibold my-4"> {{t "pslf.complete_pslf.title_success"}}</h1>
          <p class="text-lg my-4">{{this.employerMessage}}.</p>
          <p class="text-lg">{{t "pslf.complete_pslf.done_employer"}}</p>
        </div>
      </AppPageContent>
    </AppPage>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
};
export default RouteTemplate(AdminPslfFormSuccessRoute);
