import ApplicationAdapter from './application';
import ENV from 'tio-employee/config/environment';

/**
 * An extended adapter for the `UserLogin` model.
 *
 * @memberof adapters
 */
class LoginAdapter extends ApplicationAdapter {
  async sendForgotPassword({ email, type }: { email: string; type: string }) {
    const url = this.buildURL('login') + '/forgot-password';
    return await this.ajax(url, 'POST', {
      headers: {
        'Content-Type': 'application/json',
        'x-api-key': ENV.apiKey,
      },
      body: JSON.stringify({
        email,
        requestType: type,
      }),
    });
  }

  async validateResetPasswordToken(token: string) {
    const url = this.buildURL('login') + '/validate-reset-token';
    try {
      return await this.ajax(url, 'POST', {
        headers: {
          'Content-Type': 'application/json',
          'x-api-key': ENV.apiKey,
        },
        body: JSON.stringify({ token }),
      });
    } catch (error) {
      return { success: false };
    }
  }

  async resetPassword({
    password,
    passwordConfirmation,
    token,
  }: {
    password: string;
    passwordConfirmation: string;
    token: string;
  }) {
    const url = this.buildURL('login') + '/reset-password';
    return await this.ajax(url, 'POST', {
      headers: {
        'Content-Type': 'application/json',
        'x-api-key': ENV.apiKey,
      },
      body: JSON.stringify({
        password,
        passwordConfirmation,
        token,
      }),
    });
  }
}

export default LoginAdapter;
