import { template } from "@ember/template-compiler";
import { action } from '@ember/object';
import { and, not } from 'ember-truth-helpers';
import { fn } from '@ember/helper';
import { Footer, Modal } from 'tio-ui/components/modal';
import { service } from '@ember/service';
import { t } from 'ember-intl';
import { tracked } from '@glimmer/tracking';
import AccountsLinkedLoansSummary from './linked-loans-summary';
import AppContentCard from 'tio-common/components/app/content-card';
import Component from '@glimmer/component';
import MaterialIcon from 'tio-common/components/material-icon';
import TioButton from 'tio-common/components/tio/button';
import { Divider } from 'tio-ui/components/utilities';
import type { LoanSummary } from 'tio-employee/types/loan-summary';
import type AccountModel from 'tio-common/models/account';
import type EmployeeService from 'tio-employee/services/employee';
import type LoanModel from 'tio-common/models/loan';
import type PersonModel from 'tio-common/models/person';
import type RouterService from '@ember/routing/router';
import type SessionContextService from 'tio-employee/services/session-context';
import type Store from '@ember-data/store';
export interface LinkedAccountsCardSignature {
    Args: {
        accounts: AccountModel[];
        person: PersonModel;
        programName: string;
        loanSummary: LoanSummary;
    };
    Element: HTMLElement;
}
export default class LinkedAccountsCard extends Component<LinkedAccountsCardSignature> {
    @service
    sessionContext: SessionContextService;
    @service
    store: typeof Store;
    @service
    employee: EmployeeService;
    @service
    router: RouterService;
    @tracked
    showViewDetailsDialog = false;
    // @tracked showLoanInDefaultDialog = false;
    // @tracked anyLoanInDefault = null;
    @tracked
    hasAcknowledgedDefaultDialog = false;
    get activeAccounts() {
        return this.args.accounts.filter((account1: AccountModel)=>account1.isActive);
    }
    get accountInstitutionDisplayNames() {
        return this.activeAccounts.slice().map((account1: AccountModel)=>account1.institutionName);
    }
    get hasActiveLinkedAccounts() {
        return !!this.activeAccounts.length;
    }
    get showDialog() {
        return this.showLoanInDefaultDialog;
    }
    get showLoanInDefaultDialog() {
        const anyLoanInDefault1 = this.args.accounts.flatMap((account1: AccountModel)=>account1.loans).some((loan1: LoanModel)=>loan1.status === 'DEFAULT');
        return anyLoanInDefault1;
    }
    @action
    cancelDefaultLoanDialog() {
        if (this.showLoanInDefaultDialog && !this.hasAcknowledgedDefaultDialog) {
            this.hasAcknowledgedDefaultDialog = true;
        }
    }
    @action
    submit() {
        this.router.transitionTo('authenticated.repaying-student-debt.askjeni');
    }
    static{
        template(`
    <AppContentCard @titleClass="uppercase" @title="{{t 'idr.accounts.default'}}" ...attributes>
      <Divider class="py-2" />
      {{#if this.hasActiveLinkedAccounts}}
        <p>{{t "idr.accounts.linked_below"}}</p>
        <div class="grid grid-cols-1 justify-items-center min-w-full">
          <ul class="font-bold min-w-[75%] mx-4">
            {{#each this.accountInstitutionDisplayNames as |name|}}
              <li class="my-2 p-2 bg-ocean-50 flex justify-between">
                <p class="flex-wrap">{{name}}</p>
                <MaterialIcon @icon="check_circle" class="text-emerald-500" />
              </li>
            {{/each}}
          </ul>
          <div>
            <TioButton class="my-3 px-8" @linkTo="authenticated.slr.linking">
              {{t "idr.accounts.add_more_accounts"}}
            </TioButton>
            <TioButton
              @outlined={{true}}
              class="my-3 min-w-full"
              @onClick={{fn (mut this.showViewDetailsDialog) true}}
            >
              {{t "idr.accounts.view_details"}}
            </TioButton>
          </div>
        </div>
      {{else}}
        <div class="grid grid-cols-1 justify-items-center min-w-full">
          <p>{{t "no_linked_accounts_alert.please_link_loans" program=@programName}}</p>
          <div>
            <TioButton class="my-3 px-8" @linkTo="authenticated.slr.linking">
              {{t "pslf_dashboard.actions.add_accounts"}}
            </TioButton>
          </div>
        </div>
      {{/if}}
    </AppContentCard>

    <Modal
      @isOpen={{this.showViewDetailsDialog}}
      @onClose={{fn (mut this.showViewDetailsDialog) false}}
      class="min-h-64"
      @backdrop="faded"
      @size="xl"
      as |m|
    >
      <m.Header>
        {{t "idr.accounts.accounts_linked"}}
      </m.Header>
      <m.Body>
        <AccountsLinkedLoansSummary
          @accounts={{@accounts}}
          @allowOverrideEdits={{true}}
          @loanSummary={{@loanSummary}}
          @person={{@person}}
        />
      </m.Body>
    </Modal>

    <Modal
      @isOpen={{and this.showLoanInDefaultDialog (not this.hasAcknowledgedDefaultDialog)}}
      @onClose={{this.cancelDefaultLoanDialog}}
      as |m|
    >
      <m.Body>
        {{t "default_loan.text"}}
      </m.Body>

      <m.Footer>
        <Footer
          @isRunning={{false}}
          @onSubmit={{this.submit}}
          @submitText={{t "default_loan.button"}}
          @onCancel={{this.cancelDefaultLoanDialog}}
        />
      </m.Footer>
    </Modal>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
