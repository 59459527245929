import { template } from "@ember/template-compiler";
import { action } from '@ember/object';
import { concat } from '@ember/helper';
import { Dropdown } from 'tio-ui/components/collections';
import { eq } from 'ember-truth-helpers';
import { fn } from '@ember/helper';
import { service } from '@ember/service';
import { t } from 'ember-intl';
import AppHeaderInitialsIcon from 'tio-employee/components/app/header/initials-icon';
import ArrowRightOnRectangle from 'ember-static-heroicons/components/outline-24/arrow-right-on-rectangle';
import ChevronRight from 'ember-static-heroicons/components/outline-24/chevron-right';
import Component from '@glimmer/component';
import lowercase from 'ember-cli-string-helpers/helpers/lowercase';
import QuestionMarkCircle from 'ember-static-heroicons/components/outline-24/question-mark-circle';
import screenIs from 'tio-common/helpers/screen-is';
import type RoleModel from 'tio-common/models/role-view';
import type RouterService from '@ember/routing/router-service';
import type UserModel from 'tio-common/models/user';
import UserCircle from 'ember-static-heroicons/components/outline-24/user-circle';
import { Divider } from 'tio-ui/components/utilities';
const stateColors = {
    disabled: '#999',
    connecting: '#000',
    connected: '#090',
    authenticated: 'currentColor',
    error: '#c66',
    unknown: '#d90'
};
interface AppHeaderCurrentUserMenuComponentSignature {
    Args: {
        user: UserModel;
        roles: RoleModel[];
        currentRole: RoleModel;
        onRoleChange: (role: RoleModel) => void;
        onLogout: () => void;
    };
}
/**
 * Renders the user menu within the header for the current user.
 *
 * @memberof components/app/header
 */ let AppHeaderCurrentUserMenuComponent = class AppHeaderCurrentUserMenuComponent extends Component<AppHeaderCurrentUserMenuComponentSignature> {
    @service
    router: RouterService;
    /**
   * Returns the color used for the menu button. Currently used as a subtle signal for what the
   * current state of the vue integration is.
   */ get menuButtonColor() {
        // not planning to type as it is going away soon
        return stateColors.unknown;
    }
    get activeRoles() {
        return this.args.roles.filter((role1)=>role1.isActive);
    }
    @action
    handleMenuAction(key1: string) {
        switch(key1){
            case 'profile':
                this.router.transitionTo('authenticated.profile');
                break;
            case 'help':
                this.router.transitionTo('authenticated.contact-us');
                break;
        }
    }
    static{
        template(`
    <Dropdown as |d|>
      <d.Trigger @intent="default" @size="sm" @appearance="minimal">
        <div class="flex items-center font-medium">
          {{#if (screenIs "md")}}
            <div class="flex flex-col mr-2 text-right">
              <div>{{t "header.user_menu.title" name=@user.person.firstName}}</div>
              {{#unless (eq @currentRole.relationshipType "PARTICIPANT")}}
                <div class="text-xs">
                  {{t (concat "user-roles." (lowercase @currentRole.relationshipType))}}
                </div>
              {{/unless}}
            </div>
          {{/if}}
          <AppHeaderInitialsIcon @initials={{@user.person.initials}} />
        </div>
      </d.Trigger>
      <d.Menu @intent="primary" @onAction={{this.handleMenuAction}} as |Item|>
        <span class="p-2">
          {{t "header.user_menu.switch_account"}}
        </span>

        {{#each this.activeRoles as |role|}}
          <Item @key={{role.relationshipType}} @onClick={{fn @onRoleChange role}}>
            <div class="flex items-center">
              {{#if (eq @currentRole.relationshipType role.relationshipType)}}
                <ChevronRight class="w-4 mr-2" />
              {{/if}}
              {{t (concat "user-roles." (lowercase role.relationshipType))}}
            </div>
          </Item>
        {{/each}}
        <Divider />
        <Item @key="profile">
          <div class="flex items-center">
            <UserCircle class="w-4 mr-2" />
            {{t "header.user_menu.profile"}}
          </div>
        </Item>
        <Item @key="help" @withDivider={{true}}>
          <div class="flex items-center">
            <QuestionMarkCircle class="w-4 mr-2" />
            {{t "header.user_menu.help"}}
          </div>
        </Item>
        <Item @key="logout" @onClick={{@onLogout}}>
          <div class="flex items-center">
            <ArrowRightOnRectangle class="w-4 mr-2" />
            {{t "header.user_menu.logout"}}
          </div>
        </Item>
      </d.Menu>
    </Dropdown>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
};
export default AppHeaderCurrentUserMenuComponent;
