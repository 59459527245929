import { template } from "@ember/template-compiler";
import { action } from '@ember/object';
import { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import AppHeader from 'tio-employee/components/app/header';
import AppSidebar from 'tio-employee/components/app/sidebar';
import AuthRegisterPostAuthTermsDialog from 'tio-employee/components/auth/register/post-auth-terms-dialog';
import AuthSessionExpiringDialog from 'tio-employee/components/auth/session-expiring-dialog';
import Component from '@glimmer/component';
import RouteTemplate from 'ember-route-template';
import screenIs from 'tio-common/helpers/screen-is';
import type SessionContextService from 'tio-employee/services/session-context';
import type StoreService from 'tio-common/services/store';
import type UserModel from 'tio-common/models/user';
import style from 'ember-style-modifier/modifiers/style';
interface AuthentecatedRouteSignature {
    Args: {
        model: UserModel;
    };
    Blocks: {
        default: [];
    };
}
let AuthenticatedRouteComponent = class AuthenticatedRouteComponent extends Component<AuthentecatedRouteSignature> {
    @service
    sessionContext: SessionContextService;
    @service
    store: StoreService;
    @tracked
    isFinishedRegisterTermsDialog = false;
    get showRegisterTermsDialog() {
        // Use a tracked property to allow for faster closing of the dialog
        // (i.e. better UX) before the sessionContext re-calcs `needsToAcceptTerms`
        if (this.isFinishedRegisterTermsDialog) {
            return false;
        }
        return this.sessionContext.needsToAcceptAppTerms;
    }
    @action
    async didCompleteSsoRegistration() {
        this.isFinishedRegisterTermsDialog = true;
        // Reload the user with the newly created usage agreement and privacy agreement
        await this.store.findRecord('user', this.sessionContext.user.id, {
            include: 'usage-agreement,privacy-agreement,custom-register-agreements',
            reload: true
        });
    }
    static{
        template(`
    <div class="flex h-screen antialiased bg-white text-black overflow-hidden">
      {{! Padding left on element below must match the configured sidebar width }}
      <div class="flex flex-col grow {{if (screenIs 'md') 'pl-[4.275rem]'}}">

        <main
          id="tio-ember-router-scroll-container"
          class="flex grow h-full overflow-auto scroll-smooth
            {{if (screenIs 'md') 'max-w-[calc(100vw-4.275rem)]' 'max-w-[100vw]'}}"
          {{style order="2"}}
        >
          {{outlet}}
        </main>
        <AppHeader {{style order="1"}} />

      </div>
      {{! Render sidebar after header and main content to ensure it is on top of the main content }}
      <AppSidebar />
    </div>

    {{! Showing status handled by dialog component itself }}
    <AuthSessionExpiringDialog />

    {{#if this.showRegisterTermsDialog}}
      <AuthRegisterPostAuthTermsDialog
        @didSave={{this.didCompleteSsoRegistration}}
        @primaryEmailAddress={{@model.primaryEmail}}
      />
    {{/if}}
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
};
export default RouteTemplate(AuthenticatedRouteComponent);
