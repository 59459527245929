import { template } from "@ember/template-compiler";
import { Input } from 'tio-ui/components/forms';
import { on } from '@ember/modifier';
import { t } from 'ember-intl';
import Component from '@glimmer/component';
import Drawer from 'tio-ui/components/drawer';
interface AddScholarshipsDrawerSignature {
    Args: {
        isOpen: boolean;
        onClose: () => void;
    };
    Element: HTMLDivElement;
}
export default class AddScholarshipsDrawerComponent extends Component<AddScholarshipsDrawerSignature> {
    inputClasses = {
        base: 'w-full mb-6'
    };
    static{
        template(`
    <Drawer @isOpen={{@isOpen}} @onClose={{@onClose}}>
      <div class="p-6 bg-gray-50">
        <h2 class="text-grey-600 font-semibold">
          {{t "add_scholarships_drawer.add_scholarships"}}
        </h2>
        <p class="text-gray-400 text-md">{{t
            "add_scholarships_drawer.fill_out_scholarship_below"
          }}</p>
      </div>
      <div class="flex py-4 px-6">
        <div class="my-4">
          <h1 class="text-grey-600 text-2xl">{{t
              "add_scholarships_drawer.scholarship_information"
            }}</h1>
          <p>
            <span class="text-error-400 text-xl">*</span>
            {{t "add_education_drawer.required_fields"}}</p>
        </div>
      </div>
      <div class="flex flex-col py-4 px-6 w-full border-b border-gray-200">
        <label for="scholarship_name1" class="font-semibold text-gray-600">
          {{t "add_scholarships_drawer.scholarship_name"}}
          <span class="text-error-400">*</span>
        </label>
        <Input id="scholarship_name1" @classes={{this.inputClasses}} />
        <label for="scholarship_amount1" class="font-semibold text-gray-600">
          {{t "add_scholarships_drawer.scholarship_amount"}}
          <span class="text-error-400">*</span>
        </label>
        <Input id="scholarship_amount1" @classes={{this.inputClasses}} />
      </div>
      <div class="flex flex-col py-4 px-6 w-full border-b border-gray-200">
        <label for="scholarship_name2" class="font-semibold text-gray-600">
          {{t "add_scholarships_drawer.scholarship_name"}}
          <span class="text-error-400">*</span>
        </label>
        <Input id="scholarship_name2" @classes={{this.inputClasses}} />
        <label for="scholarship_amount2" class="font-semibold text-gray-600">
          {{t "add_scholarships_drawer.scholarship_amount"}}
          <span class="text-error-400">*</span>
        </label>
        <Input id="scholarship_amount2" @classes={{this.inputClasses}} />
      </div>
      <div class="flex flex-col py-4 px-6 w-full border-b border-gray-200">
        <label for="scholarship_name3" class="font-semibold text-gray-600">
          {{t "add_scholarships_drawer.scholarship_name"}}
          <span class="text-error-400">*</span>
        </label>
        <Input id="scholarship_name3" @classes={{this.inputClasses}} />
        <label for="scholarship_amount3" class="font-semibold text-gray-600">
          {{t "add_scholarships_drawer.scholarship_amount"}}
          <span class="text-error-400">*</span>
        </label>
        <Input id="scholarship_amount3" @classes={{this.inputClasses}} />
      </div>
      <div class="flex flex-col py-4 px-6 w-full border-b border-gray-200">
        <label for="scholarship_name4" class="font-semibold text-gray-600">
          {{t "add_scholarships_drawer.scholarship_name"}}
          <span class="text-error-400">*</span>
        </label>
        <Input id="scholarship_name4" @classes={{this.inputClasses}} />
        <label for="scholarship_amount4" class="font-semibold text-gray-600">
          {{t "add_scholarships_drawer.scholarship_amount"}}
          <span class="text-error-400">*</span>
        </label>
        <Input id="scholarship_amount4" @classes={{this.inputClasses}} />
      </div>
      <div class="mt-8 flex justify-end">
        <button
          type="button"
          class="flex py-3 px-4 me-2 mb-2 font-medium text-lg text-gray-800 focus:outline-none bg-white rounded-lg hover:bg-gray-50 hover:border hover:border-black"
          {{on "click" @onClose}}
        >
          {{t "cancel"}}
        </button>
        <button
          type="submit"
          class="flex py-3 px-4 me-2 mb-2 font-medium text-lg text-white focus:outline-none bg-ocean-600 rounded-lg border border-ocean-600 hover:bg-ocean-50 hover:text-ocean-800"
        >
          {{t "save"}}
        </button>
      </div>
    </Drawer>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
