import { template } from "@ember/template-compiler";
import { array } from '@ember/helper';
import { service } from '@ember/service';
import { t } from 'ember-intl';
import AppPage from 'tio-employee/components/app/page';
import AppPageContent from 'tio-employee/components/app/page/content';
import Component from '@glimmer/component';
import pageTitle from 'ember-page-title/helpers/page-title';
import type RouterService from '@ember/routing/router-service';
import RouteTemplate from 'ember-route-template';
import type Store from '@ember-data/store';
import TioPageBreadcrumbs from 'tio-common/components/tio/page/breadcrumbs';
import TioPageHeaderTitle from 'tio-common/components/tio/page/header/title';
import TuitionAssistanceFormsCertificateSubmitForReview from 'tio-employee/components/tuition-assistance/forms/certificate/submit-for-review';
import TuitionAssistanceFormsChangeRequestAlert from 'tio-employee/components/tuition-assistance/forms/change-request-alert';
import TuitionAssistanceLimitsSummary from 'tio-common/components/tuition-assistance/limits-summary';
import TuitionAssistanceProgramDetailsAppDetailsAppHistory from 'tio-common/components/tuition-assistance/program-details/app-details/app-history';
import TuitionAssistanceProgramDetailsApplicationInformation from 'tio-common/components/tuition-assistance/program-details/application-information';
import TuitionAssistanceProgramDetailsCancelRequest from 'tio-employee/components/tuition-assistance/program-details/cancel-request';
import TuitionAssistanceProgramDetailsProgramInfoCard from 'tio-common/components/tuition-assistance/program-details/program-info-card';
import ProgressTracker from 'tio-common/components/tio/progress-tracker';
import type TasApplication from 'tio-common/models/tas-application';
import type ProgressBuilder from 'tio-common/services/progress-builder';
import type { Step } from 'tio-common/components/tio/progress-tracker';
interface TAProgramInstanceCourseApplicationsShowCertificateRouteComponentSignature {
    Args: {
        model: TasApplication;
    };
}
// eslint-disable-next-line ember/no-empty-glimmer-component-classes
let TuitionAssistanceProgramInstanceCourseApplicationsShowCertificateRouteComponent = class TuitionAssistanceProgramInstanceCourseApplicationsShowCertificateRouteComponent extends Component<TAProgramInstanceCourseApplicationsShowCertificateRouteComponentSignature> {
    @service
    store: typeof Store;
    @service
    router: RouterService;
    @service
    progressBuilder: ProgressBuilder;
    get certificateCourse() {
        return this.args.model?.tasCourses?.[0];
    }
    get steps() {
        return this.progressBuilder.applicationStepList(this.args.model) as Step[];
    }
    static{
        template(`
    {{pageTitle (t "tuition_assistance.program_details.default")}}

    <AppPage>
      <TioPageHeaderTitle>
        {{t "tuition_assistance.default"}}
      </TioPageHeaderTitle>
      <TioPageBreadcrumbs as |b|>
        <b.crumb @route="authenticated.tuition-assistance.dashboard" @label={{t "dashboard"}} />
        <b.crumb
          @route="authenticated.tuition-assistance.programs.instance.course-applications.show"
          @label={{@model.tasProgramInstance.programName}}
        />
      </TioPageBreadcrumbs>

      <AppPageContent>
        <div class="my-8">
          <ProgressTracker class="hidden md:block" @steps={{this.steps}} />
        </div>
        <TuitionAssistanceFormsChangeRequestAlert
          @transitionState="EVIDENCE_NOT_APPROVED"
          @transitionLog={{@model.transitionLog}}
        />
        <div class="flex flex-wrap mb-6 mt-4 gap-6">
          <TuitionAssistanceProgramDetailsProgramInfoCard
            @programInstance={{@model.tasProgramInstance}}
            @showAttachments={{true}}
          />
          <div class="flex flex-col flex-wrap gap-4 w-full md:w-max">
            <TuitionAssistanceLimitsSummary
              @application={{@model}}
              @employee={{@model.tasProgramInstance.employee}}
              @labelClass="col-span-5"
              @valueClass="col-span-2 text-violet-800"
              as |Summary|
            >
              {{#unless Summary.isUnlimitedMaximumAnnualBenefit}}
                <Summary.Container
                  @title={{t "tuition_assistance.program_details.program_benefits"}}
                  @titleClass="font-semibold"
                  class="md:max-w-md"
                >
                  <Summary.MaxAnnualBenefitLineItem />
                </Summary.Container>
              {{/unless}}
            </TuitionAssistanceLimitsSummary>
            <TuitionAssistanceProgramDetailsApplicationInformation
              @application={{@model}}
              @isAdminView={{false}}
              @canRequestRepayment={{false}}
            />
          </div>
        </div>
        {{! @glint-expect-error investigate. Is this optional or not?}}
        <TuitionAssistanceFormsCertificateSubmitForReview @course={{this.certificateCourse}} />

        <TuitionAssistanceProgramDetailsAppDetailsAppHistory
          @application={{@model}}
          @viewType="employee"
        />
        <TuitionAssistanceProgramDetailsCancelRequest
          @application={{@model}}
          @modelTypes={{array "tas-application" "tas-program-instance"}}
          @linkText={{t "tuition_assistance.program_details.cancel_app.delete_application"}}
          @canCancel={{@model.canCancelApplication}}
        />
      </AppPageContent>
    </AppPage>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
};
export default RouteTemplate(TuitionAssistanceProgramInstanceCourseApplicationsShowCertificateRouteComponent);
