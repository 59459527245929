import { template } from "@ember/template-compiler";
import { action } from '@ember/object';
import { fn } from '@ember/helper';
import { Modal, Footer } from 'tio-ui/components/modal';
import { on } from '@ember/modifier';
import { service } from '@ember/service';
import { t } from 'ember-intl';
import { tracked } from '@glimmer/tracking';
import Component from '@glimmer/component';
import FormInput from '@frontile/forms-legacy/components/form-input';
import FormSelect from '@frontile/forms-legacy/components/form-select';
import Pencil from 'ember-static-heroicons/components/outline-24/pencil';
import Table from 'tio-common/components/table/index';
import TioButton from 'tio-common/components/tio/button';
import type { DependentRelationship } from '../forms/add-dependents';
import type DependentModel from 'tio-common/models/dependent';
import type EmployeeModel from 'tio-common/models/employee';
import type StoreService from 'tio-common/services/store';
export interface DependentTableSignature {
    Args: {
        employee: EmployeeModel;
        renderInPlace?: boolean;
    };
}
const relationships = [
    {
        type: 'DependentRelationship.CHILD',
        name: 'Child'
    },
    {
        type: 'DependentRelationship.SPOUSE',
        name: 'Spouse'
    },
    {
        type: 'DependentRelationship.PARTNER',
        name: 'Partner'
    }
];
const ageFromBirthDate = (date1: string)=>{
    return Math.floor((new Date().valueOf() - new Date(date1).getTime().valueOf()) / 3.15576e10);
};
const getDependentRelationshipName = (dependent1: DependentModel)=>{
    return relationships.find((relationship1)=>relationship1.type === dependent1.relationship)!.name;
};
export default class TuitionAssistanceDependentTable extends Component<DependentTableSignature> {
    @service
    store: StoreService;
    @tracked
    showEditDependentDialog = false;
    @tracked
    relationshipToEdit?: DependentRelationship;
    @tracked
    dependentToEdit?: DependentModel;
    @tracked
    isConfirming = false;
    @action
    toggleEditDependentDialog(dependent1: DependentModel) {
        this.dependentToEdit = dependent1;
        this.relationshipToEdit = relationships.find((relationship1)=>dependent1.relationship === relationship1.type);
        this.showEditDependentDialog = !this.showEditDependentDialog;
    }
    @action
    async submit() {
        this.isConfirming = true;
        let dependentToUpdate1 = await this.store.findRecord('dependent', this.dependentToEdit?.id);
        dependentToUpdate1.firstName = this.dependentToEdit!.firstName;
        dependentToUpdate1.lastName = this.dependentToEdit!.lastName;
        dependentToUpdate1.age = this.dependentToEdit!.age;
        dependentToUpdate1.birthDate = this.dependentToEdit!.birthDate;
        dependentToUpdate1.relationship = this.relationshipToEdit!.type;
        await dependentToUpdate1.save();
        this.isConfirming = false;
        this.showEditDependentDialog = false;
    }
    @action
    async cancel() {
        await this.dependentToEdit!.rollbackAttributes();
        this.showEditDependentDialog = !this.showEditDependentDialog;
    }
    @action
    async deleteDependentToEdit() {
        let dependnet1 = this.store.peekRecord('dependent', this.dependentToEdit?.id);
        await dependnet1.destroyRecord();
        this.showEditDependentDialog = false;
    }
    static{
        template(`
    <Table @isLoading={{false}}>
      <:thead as |options|>
        <options.tr class="font-semibold text-left bg-ocean-50 px-2 py-1">
          <options.th>{{t "common.tuition_assistance.dependent_table.header_name"}}</options.th>
          <options.th>
            {{t "common.tuition_assistance.dependent_table.header_relationship"}}
          </options.th>
          <options.th>{{t "common.tuition_assistance.dependent_table.header_age"}}</options.th>
        </options.tr>
      </:thead>
      <:tbody as |options|>
        {{#each @employee.dependents as |dependent|}}
          {{#if dependent.notDeleted}}
            <options.tr>
              <options.td data-test-dependent-table-full-name>{{dependent.fullName}}</options.td>
              <options.td data-test-dependent-table-relationship>
                {{getDependentRelationshipName dependent}}
              </options.td>
              <options.td class="traicking-wide" data-test-dependent-table-age>
                <span class="flex w-full justify-between mr-4">
                  {{ageFromBirthDate dependent.birthDate}}
                  <button
                    type="submit"
                    class="text-tio-gray-400 hover:text-tio-gray-600 cursor-pointer"
                    {{on "click" (fn this.toggleEditDependentDialog dependent)}}
                    data-test-edit-dependent-button
                  >
                    <Pencil class="h-4 w-4" />
                  </button>
                </span>
              </options.td>
            </options.tr>
          {{/if}}
        {{/each}}
      </:tbody>
    </Table>
    <Modal
      @isOpen={{this.showEditDependentDialog}}
      @onClose={{this.cancel}}
      class="min-h-64"
      @backdrop="faded"
      @size="xl"
      data-test-edit-dependent-dialog
      @renderInPlace={{@renderInPlace}}
      as |m|
    >
      <m.Header>{{t "common.tuition_assistance.edit_dependent_modal.header"}}</m.Header>
      <m.Body>
        <form class="bg-white md:col-span-2 pb-8">
          <div class="flex flex-col max-w-2xl">
            <div class="flex mb-6">
              <div class="mt-2 mr-2">
                <FormInput
                  data-legacy-input
                  @label={{t "common.tuition_assistance.add_dependent_modal.first_name"}}
                  @type="text"
                  @value={{this.dependentToEdit.firstName}}
                  @onChange={{fn (mut this.dependentToEdit.firstName)}}
                  data-test-edit-dependent-first-name
                />
              </div>
              <div class="mt-2">
                <FormInput
                  data-legacy-input
                  @label={{t "common.tuition_assistance.add_dependent_modal.last_name"}}
                  @type="text"
                  @value={{this.dependentToEdit.lastName}}
                  @onChange={{fn (mut this.dependentToEdit.lastName)}}
                  data-test-edit-dependent-last-name
                />
              </div>
            </div>
            <div class="flex flex-col w-1/2">
              <div class="mt-2 mb-6">
                <FormSelect
                  data-legacy-input
                  @label={{t "common.tuition_assistance.add_dependent_modal.relationship"}}
                  @options={{relationships}}
                  @selected={{this.relationshipToEdit}}
                  @onChange={{fn (mut this.relationshipToEdit)}}
                  {{! @glint-expect-error}}
                  @renderInPlace={{true}}
                  @containerClass="t-select-relationship"
                  as |relationship|
                >
                  {{! @glint-expect-error}}
                  {{relationship.name}}
                </FormSelect>
              </div>
              <div class="mt-2">
                <FormInput
                  data-legacy-input
                  @label={{t "common.tuition_assistance.add_dependent_modal.birth_date"}}
                  @type="date"
                  @value={{this.dependentToEdit.birthDate}}
                  @onChange={{fn (mut this.dependentToEdit.birthDate)}}
                  data-test-edit-dependent-birth-date
                />
              </div>
            </div>
          </div>
        </form>
      </m.Body>

      <m.Footer>
        <Footer
          @isRunning={{this.isConfirming}}
          @onSubmit={{this.submit}}
          @onCancel={{this.cancel}}
        >
          <:left>
            <TioButton @warning={{true}} @onClick={{this.deleteDependentToEdit}}>
              {{t "common.tuition_assistance.edit_dependent_modal.delete_button"}}
            </TioButton>
          </:left>
        </Footer>
      </m.Footer>
    </Modal>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
