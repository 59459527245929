import { template } from "@ember/template-compiler";
import { action } from '@ember/object';
import { dropTask } from 'ember-concurrency';
import { fn } from '@ember/helper';
import { service } from '@ember/service';
import { t } from 'ember-intl';
import { tracked } from '@glimmer/tracking';
import authRegisterPayrollIdSchema from 'tio-common/validation-schema/forms/auth-register-payroll-id-form';
import CompanySettingModel from 'tio-common/models/company-setting';
import Component from '@glimmer/component';
import ENV from 'tio-employee/config/environment';
import errorsForField from 'tio-common/helpers/errors-for-field';
import formatSubdomainRedirectUrl from 'tio-common/utils/format-subdomain-redirect-url';
import FormInput from '@frontile/forms-legacy/components/form-input';
import LoginHere from '../login-here';
import TioButton from 'tio-common/components/tio/button';
import type { IntlService } from 'ember-intl';
import type NotificationsService from '@frontile/notifications/services/notifications';
import type PreRegisterService from '../../../services/pre-register.ts';
import type RouterService from '@ember/routing/router-service';
import type StoreService from 'tio-common/services/store';
interface AuthRegisterPayrollIdFormComponentSignature {
    Args: {
        settings: CompanySettingModel;
        email: string;
    };
}
export default class AuthRegisterPayrollIdForm extends Component<AuthRegisterPayrollIdFormComponentSignature> {
    @service
    intl: IntlService;
    @service
    store: StoreService;
    @service
    preRegister: PreRegisterService;
    @service
    router: RouterService;
    @service
    notifications: NotificationsService;
    @tracked
    email = this.emailFromQueryParams;
    @tracked
    payrollId = '';
    @tracked
    lastName = '';
    @tracked
    isLoading = false;
    constructor(owner1: unknown, args1: AuthRegisterPayrollIdFormComponentSignature['Args']){
        super(owner1, args1);
        this.email = args1.email;
    }
    get emailFromQueryParams() {
        const urlParams1 = new URLSearchParams(window.location.search);
        return decodeURIComponent(urlParams1.get('email') || '');
    }
    get company() {
        return this.preRegister.sparseCompany;
    }
    get formModel() {
        return {
            email: this.email,
            payrollId: this.payrollId,
            lastName: this.lastName
        };
    }
    get validationErrors() {
        try {
            authRegisterPayrollIdSchema?.validateSync?.(this.formModel, {
                abortEarly: false
            });
        } catch (err1) {
            return err1.inner || [];
        }
        return [];
    }
    get registerPageCopy() {
        return (this.args.settings.registerPageCopy || this.intl.t('login.register_page_default', {
            companyName: this.companyName
        }));
    }
    get companyName() {
        return this.args.settings.companyName || '';
    }
    get isDisabled() {
        return !this.email || !this.payrollId || !this.lastName || this.validationErrors.length;
    }
    registerTask = dropTask(async ()=>{
        const { host: host1 } = this.store.adapterFor('application');
        const response1 = await fetch(`${host1}/preregister`, {
            method: 'POST',
            body: JSON.stringify({
                email: this.email,
                company_id: this.company?.id || null,
                payroll_id: this.payrollId,
                last_name: this.lastName
            }),
            headers: {
                'Content-Type': 'application/json',
                'x-api-key': ENV.apiKey
            }
        });
        if (response1.ok) {
            const res1 = await response1.json();
            if (res1.sub_domain) {
                const redirectUrl1 = formatSubdomainRedirectUrl({
                    subDomain: res1.sub_domain,
                    domain: this.preRegister.parseHostname(window.location.hostname).domain,
                    email: this.email,
                    route: 'register'
                });
                window.location.href = redirectUrl1;
            } else {
                this.router.transitionTo('register.confirmation');
            }
        } else {
            const res1 = await response1.json();
            this.notifications.add(res1.error || 'An Error Occurred', {
                appearance: 'error'
            });
        }
    });
    @action
    submit(e1?: Event) {
        e1?.preventDefault();
        this.isLoading = true;
        this.registerTask.perform();
        this.isLoading = false;
    }
    static{
        template(`
    <form>
      <h1 class="text-lg mb-6 text-center"> {{this.registerPageCopy}}</h1>
      <div class="mb-10">
        <FormInput
          data-legacy-input
          @label="Partner ID"
          @value={{this.payrollId}}
          @onInput={{fn (mut this.payrollId)}}
          @errors={{errorsForField "payrollId" schemaErrors=this.validationErrors}}
        />
      </div>
      <div class="mb-10">
        <FormInput
          data-legacy-input
          @label="Last Name"
          @value={{this.lastName}}
          @onInput={{fn (mut this.lastName)}}
          @errors={{errorsForField "lastName" schemaErrors=this.validationErrors}}
        />
      </div>
      <div class="mb-10">
        <FormInput
          data-legacy-input
          @label={{t "login.email"}}
          @value={{this.email}}
          @hint="Please provide your personal email address. This is where you will receive your invite."
          @onInput={{fn (mut this.email)}}
          @type="email"
          @errors={{errorsForField "email" schemaErrors=this.validationErrors}}
        />
      </div>

      <div class="flex flex-col items-center">
        <TioButton @onClick={{this.submit}} @disabled={{this.isDisabled}} class="w-36 mb-10">
          {{t "submit"}}
        </TioButton>
      </div>
      <LoginHere />
    </form>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
